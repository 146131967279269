import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connectModal } from 'redux-modal';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ButtonContainer } from './ModalSetPemKey.styled';
import { Button, Title } from '@smartups-sl/react-component-library';

class ModalSetPemKey extends Component {
  componentDidMount() {}

  setPemKey = (e) => {
    const { action } = this.props;
    e.preventDefault();
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      // const obj = JSON.stringify(text);
      action(text);
    };
    reader.readAsText(e.target.files[0]);
  };

  render() {
    const { t, show, handleHide } = this.props;
    return (
      <Modal size="lg" show={show} onHide={handleHide} centered backdrop={true}>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center mt-5">
              <Title type="h4" variant="white">
                {t('select_pem')}
              </Title>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6 text-center mt-3">
              <input
                className="form-control"
                type="file"
                onChange={(event) => this.setPemKey(event)}
              />
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <ButtonContainer className="col-12 col-md-3 col-lg-3 ">
              <Button
                type="button"
                className="btn btn-lg btn-block"
                variant="primary"
                onClick={() => handleHide()}
              >
                Cancel
              </Button>
            </ButtonContainer>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

ModalSetPemKey.propTypes = {
  show: PropTypes.any,
  handleHide: PropTypes.func,
  action: PropTypes.func,
};

export default connectModal({ name: 'ModalSetPemKey' })(withTranslation()(ModalSetPemKey));
