import React, { useState } from 'react';
import List from '../userListReview';
import Filter from '../userReviewFilter';
import { Title } from '@smartups-sl/react-component-library';
import { useTranslation } from 'react-i18next';

const UserReviewFilterListContainer = (props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState(undefined);

  const handlerOnSubmitFilter = (values) => {
    let newFilter = {};

    const valuesFilters = Object.entries(values);
    valuesFilters.forEach((value) => {
      if (value[1] && value[1].toString().length > 0) {
        newFilter[value[0]] = value[1];
      }
    });

    setFilters(newFilter);
  };

  const handlerCleanFilter = async (resetForm) => {
    resetForm();
    setFilters(undefined);
  };

  const handleSetFilter = async (filter) => {
    setFilters(filter);
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <Title type="h3" variant="secondary">
            {t('forms.user.titleReview')}
          </Title>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <Filter onSubmit={handlerOnSubmitFilter} onClean={handlerCleanFilter} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10">
          <List className="marg-top-1rem" filters={filters} setFilters={handleSetFilter} />
        </div>
      </div>
    </>
  );
};

UserReviewFilterListContainer.propTypes = {};

export default UserReviewFilterListContainer;
