import React from 'react';
import PropTypes from 'prop-types';
import Tree from '../../../../components/administration/user/unilevelTree';
import Layout from '../../../../components/layouts/verticalLayout';

const AdminTreePage = (props) => {
  return (
    <Layout
      body={
        <>
          <Tree admin uuid={props.uuid || null} />
        </>
      }
    />
  );
};

AdminTreePage.propTypes = {
  uuid: PropTypes.string,
};

export default AdminTreePage;
