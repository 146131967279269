import styled from 'styled-components';

export const StyledApp = styled.div`
  font-family: ${({ theme }) => theme.fonts.family} !important;
  font-size: ${({ theme }) => theme.fonts.size.md};
`;

export const CenterContainer = styled.div`
  height: 80%;
`;

export const ButtonsContainer = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
`;
