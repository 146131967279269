import { FormFilter } from '@smartups-sl/react-complex-components';
import { InputTextFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dataModel, validationDataModel } from './newsFilter.data.form';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import { NEWS_STATUS, PLATFORMS } from '../../../../constants/index';

const NewsFilterForm = (props) => {
  const { t } = useTranslation();
  const { setFilters } = props;
  const [initialValues, setInitialValues] = useState(undefined);

  const filters = useSelector(getFilterSelector);
  const back = useSelector(getBackFilterSelector);

  let init = undefined;
  if (back && filters?.filters) {
    init = filters.filters;
  }

  const forceResetInitValues = () => {
    setFilters(dataModel);
    setInitialValues(dataModel);
  };

  return (
    <>
      <FormFilter
        dataModel={dataModel}
        initialValues={init ? init : initialValues}
        forceResetValues={forceResetInitValues}
        validationDataModel={validationDataModel}
        title={t('forms.news.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.news.buttons.filter')}
        textButtonClean={t('forms.news.buttons.clean')}
        formName="NewsFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="secondary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12 col-md-4 col-lg-4">
            <Field
              id="title"
              className="form-control"
              labelField={t('forms.news.labels.title')}
              name="title"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <Field
              id="status"
              labelField={t('forms.news.labels.status')}
              name="status"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={NEWS_STATUS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
              addOptionEmpty
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <Field
              id="platform"
              labelField={t('forms.news.labels.platform')}
              name="platform"
              component={InputSelectFormik}
              placeholder=""
              optionLabel="label"
              optionValue="code"
              options={PLATFORMS.map((item) => ({
                ...item,
                label: t(item.label),
              }))}
              classNamePrefix="dark"
              addOptionEmpty
            />
          </div>
        </div>
      </FormFilter>
    </>
  );
};

NewsFilterForm.propTypes = {
  setFilters: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default NewsFilterForm;
