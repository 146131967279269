import * as Yup from 'yup';

export const dataModel = {
  title: '',
  status: '',
  platform: '',
};

export const validationDataModel = Yup.object().shape({
  title: Yup.string().optional('forms.validations.required'),
  status: Yup.string().optional('forms.validations.required'),
  platform: Yup.string().optional('forms.validations.required'),
});
