import React from 'react';
import PropTypes from 'prop-types';
import Form from '../../../../components/administration/user/userBlockReviewForm';
import Layout from '../../../../components/layouts/verticalLayout';

const UserReviewBlockFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <Form uuid={props.uuid} />
        </>
      }
    />
  );
};

UserReviewBlockFormPage.propTypes = {
  uuid: PropTypes.string,
};

export default UserReviewBlockFormPage;
