import React from 'react';
import PropTypes from 'prop-types';
// import { getTimeZoneSelector } from '../../../../store/selectors/navSelector';
// import SelectTimeZone from './components/selectTimeZone';
import Languages from './components/languages';
import UserMenuHeader from './components/menu';
// import LoadingModal from '../modals/LoadingModal';
import { ToastContainer, Flip } from 'react-toastify';
import { HeaderContainer, Logo, LogoMobile, SidebarButtonContainer, SidebarButton } from './styled';
import ModalInfo from '../../../utils/modalInfo/ModalInfo';
import ModalSetPemKey from '../../../utils/modalSetPemKey/ModalSetPemKey';
import LoadingModal from '../../../utils/LoadingModal/LoadingModal';

const Header = (props) => {
  const { showUserMenu, type, token, withSidebar } = props;
  let showMenu = false;

  if (
    (showUserMenu !== undefined && showUserMenu === true && token) ||
    (token && showUserMenu === undefined)
  ) {
    showMenu = true;
  }

  return (
    <>
      <LoadingModal />
      <ModalInfo></ModalInfo>
      <ModalSetPemKey></ModalSetPemKey>
      <HeaderContainer
        type={type || 'default'}
        className={`row ${type === 'login' ? 'justify-content-end' : 'justify-content-between'}`}
      >
        {type !== 'login' && (
          <div className="col-2 col-md-6 col-lg-3 align-self-center ">
            <Logo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_header_white.png`}
              alt="logo"
              className="logo d-none d-md-block"
            />
            <LogoMobile
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_login.png`}
              alt="logo"
              className="logo d-block d-md-none"
            />
          </div>
        )}

        <div
          className={`${
            type === 'login' ? 'languages-login' : ''
          } col-10 col-md-6 col-lg-4 header-right-options align-self-center`}
        >
          {/* <LoadingModal /> */}
          {/* {showMenu && <SelectTimeZone timeZone={timeZone} />} */}
          <Languages />
          {showMenu ? (
            <>
              {withSidebar ? (
                <SidebarButtonContainer className="d-block d-md-none">
                  <SidebarButton
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasExample"
                    aria-controls="offcanvasExample"
                  >
                    <i className="fas fa-bars" />
                  </SidebarButton>
                  {/* <SidebarButton
                      className="btn border-0"
                      onClick={() => {
                        toggleFunc();
                      }}
                    >
                      <i className="fas fa-bars" />
                    </SidebarButton> */}
                </SidebarButtonContainer>
              ) : (
                <UserMenuHeader />
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </HeaderContainer>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
        transition={Flip}
      />
    </>
  );
};

Header.propTypes = {
  showUserMenu: PropTypes.bool,
  type: PropTypes.string,
  role: PropTypes.string,
  token: PropTypes.string,
  withSidebar: PropTypes.bool,
  toggleFunc: PropTypes.func,
};

export default Header;
