import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';

import { InputTextFormik, InputSelectFormik } from '@smartups-sl/react-forms-components';

// import { USER_ROLES } from '../../../../constants/index';
import { dataModel, validationDataModel, setInitialValues } from './userForm.data.form';

import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';

const UserForm = (props) => {
  const { t, handleSubmit, onback, nationalities } = props;
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Title type="h3">{t('forms.user.titleEdit')}</Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="name"
                  className="form-control"
                  labelField={t('forms.user.labels.name')}
                  name="name"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="surname"
                  className="form-control"
                  labelField={t('forms.user.labels.surname')}
                  name="surname"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="email"
                  className="form-control"
                  labelField={t('forms.user.labels.email')}
                  name="email"
                  component={InputTextFormik}
                  fieldType="email"
                  placeholder=""
                  readOnly={true}
                />
              </div>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <Field
                  id="role"
                  className="form-control"
                  labelField={t('forms.user.labels.role')}
                  name="role"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="value"
                  options={USER_ROLES}
                  readOnly={true}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-12">
                <Field
                  id="nationality"
                  className="form-control"
                  labelField={t('forms.user.labels.nationality')}
                  name="nationality"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="countryName"
                  optionValue="uuid"
                  options={nationalities}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.user.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.user.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

UserForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  nationalities: PropTypes.array,
};

const UserFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'UserForm',
})(withTranslation()(UserForm));

export default UserFormF;
