import { createAction } from 'redux-actions';
import { apiGet, apiPost, apiPut, apiDelete } from '../../api/index';
import {
  urlDelUser,
  urlGetUser,
  urlUpdateUser,
  urlResetTFAUser,
  urlRecoveryTFA,
  urlVerifyTwoFA,
  urlGetunilevelTree,
  urlGetAdminunilevelTree,
  urlFilterUserDuplicated,
  urlPutUserDuplicated,
  urlFilterUserBlockRequest,
  urlNews,
  urlMarketing,
} from '../../api/urls';
import {
  DELETE_USER,
  GET_USER,
  UPDATE_USER,
  RESET_TFA_USER,
  RECOVERY_TFA_USER,
  VERIFY_2FA,
  GET_UNILEVEL_TREE,
  FILTER_MARKETING,
  CREATE_RESOURCE,
  GET_RESOURCE,
  UPDATE_RESOURCE,
  DELETE_RESOURCE,
  PAGE_SIZE_10,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.name) filter += '&name=' + filters.name;
    if (filters.lang) filter += '&lang=' + filters.lang;
    if (filters.category) filter += '&category=' + filters.category;
  }

  return filter;
};

/**
 * Delete user by uuid.
 */
export const deleteUser = createAction(DELETE_USER, (param) =>
  apiDelete(urlDelUser, param, true)()
);

/**
 * Get user by uuid.
 */
export const getUserByUuid = createAction(GET_USER, (param) => apiGet(urlGetUser, param, true)());

/**
 * Update User.
 */
export const updateUser = createAction(UPDATE_USER, (obj, params) =>
  apiPut(urlUpdateUser, obj, params, true)()
);

/**
 * Reset TFA.
 */
export const resetTFA = createAction(RESET_TFA_USER, (param) =>
  apiGet(urlResetTFAUser, param, true)()
);

/**
 * Recovert TFA.
 */
export const recoveryTFA = createAction(RECOVERY_TFA_USER, (param) =>
  apiGet(urlRecoveryTFA, param, true)()
);

/**
 * Verify 2fa.
 */
export const verifyTfa = createAction(VERIFY_2FA, (obj, param) =>
  apiPost(urlVerifyTwoFA, obj, param, true)()
);

/**
 * Get unilevel tree.
 */
export const getUnilevelTree = createAction(GET_UNILEVEL_TREE, (param) =>
  apiGet(urlGetunilevelTree, param, true)()
);

/**
 * Get unilevel tree.
 */
export const getUnilevelAdminTree = createAction(GET_UNILEVEL_TREE, (param) =>
  apiGet(urlGetAdminunilevelTree, param, true)()
);

/**
 * Get user by uuid.
 */
export const getDuplicatedUser = createAction(GET_USER, (param) =>
  apiGet(urlFilterUserDuplicated, param, true)()
);

/**
 * Update duplicated User status.
 */
export const putDataDuplicatedUser = createAction(UPDATE_USER, (obj, params) =>
  apiPut(urlPutUserDuplicated, obj, params, true)()
);

/**
 * Get user by uuid.
 */
export const getBlockUserRequest = createAction('GET_BLOCK_REQUEST', (param) =>
  apiGet(urlFilterUserBlockRequest, param, true)()
);

/**
 * Update duplicated User status.
 */
export const putBlockRequestUser = createAction('UPDATE_BLOCK_USER', (obj, params) =>
  apiPut(urlFilterUserBlockRequest, obj, params, true)()
);

/**
 * Create news.
 */
export const createNewsDraft = createAction('CREATE_NEWS', (obj) =>
  apiPost(urlNews, obj, undefined, true)()
);

/**
 * Get news by uuid.
 */
export const getNewsByUuid = createAction('GET_NEWS', (param) => apiGet(urlNews, param, true)());

/**
 * Update news.
 */
export const modifyNews = createAction('UPDATE_NEWS', (obj, param) =>
  apiPut(urlNews, obj, param, true)()
);

/**
 * Create Resource.
 */
export const createResource = createAction(CREATE_RESOURCE, (obj) =>
  apiPost(urlMarketing, obj, undefined, true)()
);

/**
 * Get Resource by uuid.
 */
export const getResourceByUuid = createAction(GET_RESOURCE, (param) =>
  apiGet(urlMarketing, param, true)()
);

/**
 * Update Resource.
 */
export const updateResource = createAction(UPDATE_RESOURCE, (obj, param) =>
  apiPut(urlMarketing, obj, param, true)()
);

/**
 * Delete resource by uuid.
 */
export const deleteResource = createAction(DELETE_RESOURCE, (param) =>
  apiDelete(urlMarketing, param, true)()
);

/**
 * Filter list resources.
 */
export const filterMarketing = createAction(FILTER_MARKETING, (filter, param) =>
  apiGet(urlMarketing + generateFilterQuery(filter), param, true)()
);
