import styled from 'styled-components';

export const AdministrationContainer = styled.div`
  width: ${(props) => (props.expanded ? 'calc(100% - 80px)' : 'calc(100% - 240px)')};
`;

export const ButtonCollapseSidebar = styled.button`
  color: ${(props) => (props.styleType && props.styleType === 'pink' ? '#e81d75' : '#939598')};
  background: none !important;
  font-size: 1.2rem;
  border: 0px;

  &:hover {
    color: ${(props) => (props.styleType && props.styleType === 'pink' ? '#e81d75' : '#e81d75')};
    text-decoration: underline;
  }
`;
