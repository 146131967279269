import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import AdministrationLayout from '../../../layouts/administrationLayout';
import * as AdminServices from '../../../../services/AdminService';
import UserForm from './userForm.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';
import * as GeneralServices from '../../../../services/GeneralServices';

class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
      nationalities: undefined,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getUser(this.props.uuid).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result });
        }
      });
    }

    GeneralServices.getAllNationalities().then((result) => {
      if (result && result.length > 0) {
        this.setState({ nationalities: result });
      }
    });
  }

  handleSubmit = async (values) => {
    try {
      if (values && this.props.uuid) {
        const result = await AdminServices.updateDataUser(values, this.props.uuid);

        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          title = this.props.t('forms.user.messages.update.title');
          text = this.props.t('forms.user.messages.update.success');
          icon = 'success';
        } else {
          title = 'Error';
          text = this.props.t('forms.user.messages.update.error');
          icon = 'error';
        }

        let okAction = undefined;
        okAction = () => {
          this.props.history.push(PATHS.USER_LIST);
        };
        showDialog(title, text, icon, false, 'OK', false, okAction);
      }
    } catch (error) {
      //console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.USER_LIST);
  };

  render() {
    return (
      <AdministrationLayout
        body={
          <>
            <UserForm
              nationalities={this.state.nationalities || []}
              initialValues={this.state.values}
              onSubmit={this.handleSubmit}
              onback={this.handleOnback}
            />
          </>
        }
      />
    );
  }
}

UserContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserContainer));
