import React from 'react';
import List from '../../../../components/administration/user/userReviewFilterList';
import List2 from '../../../../components/administration/user/userBlockReviewFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const UserListReviewPage = (props) => {
  return (
    <Layout
      body={
        <>
          <div className="row">
            <div className="col-12 no-padding">
              <List />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-5">
              <List2 />
            </div>
          </div>
        </>
      }
    />
  );
};

UserListReviewPage.propTypes = {};

export default UserListReviewPage;
