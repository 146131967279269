import { handleActions } from 'redux-actions';

import {
  CLEAN_AUTH,
  LOGIN_SUCCESS,
  UPDATE_TOKEN,
  SET_AUTH_PROFILE,
  UPDATE_PROFILE,
  SET_PEM_KEY,
} from '../../constants/index';

export const auth = handleActions(
  {
    [CLEAN_AUTH]: () => ({}),
    [LOGIN_SUCCESS]: (state, action) => ({
      ...state,
      status: {
        ...state.status,
        token: action.payload.token,
        auh2fa: action.payload.auth2fa,
      },
    }),
    [SET_AUTH_PROFILE]: (state, action) => ({
      ...state,
      info: action.payload.data,
    }),
    [UPDATE_PROFILE]: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    [UPDATE_TOKEN]: (state, action) => ({
      ...state,
      status: {
        ...state.status,
        token: action.payload.token,
        auh2fa: action.payload.auh2fa,
      },
    }),
    [SET_PEM_KEY]: (state, action) => ({
      ...state,
      pemKey: action.payload.data,
    }),
  },
  {},
);
