import * as Yup from 'yup';

export const dataModel = {
  name: '',
  email: '',
  uuid: '',
  nickname: '',
  active: '',
  passedKYC: '',
};

export const validationDataModel = Yup.object().shape({
  name: Yup.string().optional('forms.validations.required'),
  email: Yup.string().optional('forms.validations.required'),
  uuid: Yup.string().optional('forms.validations.required'),
  nickname: Yup.string().optional('forms.validations.required'),
  active: Yup.string().optional('forms.validations.required'),
  passedKYC: Yup.string().optional('forms.validations.required'),
});
