import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import NewsForm from './newsForm.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      values: null,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getNews(this.props.uuid).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result, edit: true });
        }
      });
    }
  }

  handleSubmit = async (values) => {
    try {
      if (values) {
        let result = undefined;

        if (values.image && typeof values.image === 'string' && values.image.length > 0) {
          values.image = values.image.replace('data:image/jpeg;base64,', '');
          values.image = values.image.replace('data:image/png;base64,', '');
          values.image_miniature = values.image_miniature.replace('data:image/jpeg;base64,', '');
        }
        if (this.state.edit && this.props.uuid) {
          result = await AdminServices.updateNews(values, this.props.uuid);
        } else {
          result = await AdminServices.createNews(values);
        }

        let title = '';
        let text = '';
        let icon = '';
        if (result && result === 'OK') {
          if (this.state.edit) {
            title = this.props.t('forms.news.messages.update.title');
            text = this.props.t('forms.news.messages.update.success');
          } else {
            title = this.props.t('forms.news.messages.create.title');
            text = this.props.t('forms.news.messages.create.success');
          }
          icon = 'success';
        } else {
          title = 'Error';
          text = this.props.t('forms.news.messages.create.error');
          icon = 'error';
        }
        let okAction = undefined;
        okAction = () => {
          this.props.history.push(PATHS.NEWS_LIST);
        };
        showDialog(title, text, icon, false, 'OK', false, okAction);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  handleOnback = () => {
    this.props.history.push(PATHS.NEWS_LIST);
  };

  render() {
    return (
      <NewsForm
        edit={this.state.edit}
        initialValues={this.state.values}
        onSubmit={this.handleSubmit}
        onback={this.handleOnback}
        newValues={this.state.values}
      />
    );
  }
}

NewsContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NewsContainer));
