import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router-dom';

import RolesPermisionsPage from '../../pages/administration/rolesPermissions/list';
import FormRolesPermisionsPage from '../../pages/administration/rolesPermissions/form';
import UserListPage from '../../pages/administration/user/list';
import UserListReviewPage from '../../pages/administration/user/review';
import UserFormPage from '../../pages/administration/user/form';
import UserReviewFormPage from '../../pages/administration/user/reviewForm';
import UserReviewBlockFormPage from '../../pages/administration/user/reviewBlock';
import PATHS from '../../constants/paths';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import UserTreePage from '../../pages/administration/user/tree';
import EmployerRoutes from './EmployerRoutes';
import AdminTreePage from '../../pages/administration/admin/tree';
import NewsRoutes from './NewsRoutes';
import MarketingRoutes from './MarketingRoutes';

class RoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute exact path={PATHS.USER_LIST} component={UserListPage} />

        <AuthAdminRoute
          exact
          path={PATHS.USER_REVIEW_DETAIL}
          component={(props) => <UserReviewFormPage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute
          exact
          path={PATHS.USER_BLOCK_DETAIL}
          component={(props) => <UserReviewBlockFormPage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute exact path={PATHS.USER_LIST_REVIEW} component={UserListReviewPage} />
        {/* <AuthAdminRoute exact path={PATHS.ADMIN_TREE} component={AdminTreePage} /> */}
        <AuthAdminRoute
          exact
          path={PATHS.ADMIN_TREE}
          component={(props) => <AdminTreePage uuid={props.match.params.uuid || null} />}
        />

        <AuthAdminRoute
          exact
          path={PATHS.USER_TREE}
          component={(props) => <UserTreePage uuid={props.match.params.uuid} />}
        />
        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_USER}
          component={(props) => <UserFormPage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute exact path={PATHS.PERMISSIONS} component={RolesPermisionsPage} />

        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_PERMISSIONS}
          component={(props) => <FormRolesPermisionsPage uuid={props.match.params.uuid} />}
        />

        <AuthAdminRoute path={PATHS.NEWS} component={NewsRoutes} />

        <AuthAdminRoute path={PATHS.MARKETING} component={MarketingRoutes} />

        <AuthAdminRoute path={PATHS.EMPLOYER} component={EmployerRoutes} />

        <AuthAdminRoute path={PATHS.ADMIN} render={() => <Redirect to={PATHS.ADMINISTRATIONS} />} />
      </Switch>
    );
  }
}

RoutesContainer.propTypes = {};

export default RoutesContainer;
