// import { FormFilter } from '@smartups-sl/react-complex-components';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { Field, withFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dataModel, validationDataModel, setInitialValues } from './userFilter.data.form';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import { ButtonContainer } from './userReviewFilter.style';
import { Button } from '@smartups-sl/react-basic-components';

const UserFilterForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  // const { t } = useTranslation();
  // const { setFilters } = props;
  // const [initialValues, setInitialValues] = useState(undefined);

  const filters = useSelector(getFilterSelector);
  const back = useSelector(getBackFilterSelector);

  let init = undefined;
  if (back && filters?.filters) {
    init = filters.filters;
  }

  // const forceResetInitValues = () => {
  //   setFilters(dataModel);
  //   setInitialValues(dataModel);
  // };

  return (
    <>
      <form className="form-horizontal" onSubmit={handleSubmit} autoComplete="off">
        <div className="row justify-content-end">
          <div className="col-12 col-md-4 col-lg-4">
            <Field
              id="name"
              className="form-control"
              name="name"
              component={InputTextFormik}
              fieldType="text"
              placeholder={t('forms.user.labels.name')}
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4">
            <Field
              id="email"
              className="form-control"
              name="email"
              component={InputTextFormik}
              fieldType="text"
              placeholder={t('forms.user.labels.email')}
            />
          </div>
          <div className="col-12 col-md-4 col-lg-4 d-flex justify-content-between">
            <Field
              id="nickname"
              className="form-control"
              name="nickname"
              component={InputTextFormik}
              fieldType="text"
              placeholder={t('forms.user.labels.nickname')}
              classNameContainer="flex-grow-1"
            />
            <ButtonContainer>
              <Button type="submit" className="btn btn-md me-2" variant="primary">
                <i className="fas fa-search"></i>
              </Button>
              {/* {filtered && ( */}
              <Button
                type="button"
                className="btn btn-md"
                variant="secondary"
                onClick={() => onClean(props.resetForm)}
                title={t('cleanFilter')}
              >
                <i className="fas fa-trash"></i>
              </Button>
            </ButtonContainer>
          </div>
        </div>
      </form>

      {/* <FormFilter
        dataModel={dataModel}
        initialValues={init ? init : initialValues}
        forceResetValues={forceResetInitValues}
        validationDataModel={validationDataModel}
        title={t('forms.user.titleFilter')}
        setFilters={setFilters}
        textButtonSumbit={t('forms.employer.buttons.filter')}
        textButtonClean={t('forms.employer.buttons.clean')}
        formName="UserFilterForm"
        classNamefilterContainer="dark-filter-container"
        variantTitle="secondary"
        animationArrowClassname="filter-arrow"
      >
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <Field
              id="name"
              className="form-control"
              labelField={t('forms.user.labels.name')}
              name="name"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <Field
              id="email"
              className="form-control"
              labelField={t('forms.user.labels.email')}
              name="email"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <Field
              id="nickname"
              className="form-control"
              labelField={t('forms.user.labels.nickname')}
              name="nickname"
              component={InputTextFormik}
              fieldType="text"
              placeholder=""
            />
          </div>
        </div>
      </FormFilter> */}
    </>
  );
};

UserFilterForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const UserFilterFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'UserFilterForm',
})(withTranslation()(UserFilterForm));

export default UserFilterFormF;

// export default UserFilterForm;
