import { combineReducers } from 'redux';
import { reducer as modal } from 'redux-modal';
// import { ignoreActions } from 'redux-ignore';

import { auth } from './auth';
import { user } from './user';
import { filters } from './filters';

export default combineReducers({
  /* GENERATOR_INJECT_ADD_REDUCER */
  modal,
  filters,
  auth,
  user,
});
