import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import {
  Row,
  ColNopadding,
  NodeContainer,
  // Medal,
  Arrow,
  ColArrow,
} from './styled';
// import { USER_LEVELS, BULRRUNCOIN } from '../../../../../constants/index';
// import { formatMoney } from '../../../../../utils/formats/numbers';
const TreeNode = (props) => {
  const { node, firstLevel, nodeVisited } = props;
  // const { t } = useTranslation();
  const level1 = firstLevel + 1;
  const visited = nodeVisited.includes(node.userUuid);
  const levelArrows = firstLevel % 2;
  const noImage = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;
  // const currentLevel = USER_LEVELS.find(
  //   (element) => element.value === node.rank
  // );
  return (
    <div
      key={`${node.userUuid}_node`}
      id={node.userUuid}
      className={`node-hidden customNode ${node.level === level1 ? 'nodeLevel1' : ''}`}
    >
      <NodeContainer status={node.status} className="nodeAction">
        <Row className="row align-items-center">
          {node.image && node.image.length > 0 ? (
            <ColNopadding className="col-4">
              <img alt="Avatar" src={node.image}></img>
            </ColNopadding>
          ) : (
            <ColNopadding className="col-4">
              <img alt="Avatar" src={noImage}></img>
            </ColNopadding>
          )}
          <ColNopadding className="col-8">
            {/* {currentLevel && (
              <Row className="row">
                <ColNopadding className="col-6 node-Text">
                  {t(currentLevel.label)}
                </ColNopadding>
                <ColNopadding className="col-6 node-Text text-right">
                  <Medal
                    src={`/assets/images/images-resources/${currentLevel?.image}`}
                  ></Medal>
                </ColNopadding>
              </Row>
            )} */}
            {/* {node.nickname && node.nickname.length > 0 && (
              <Row className="row">
                <ColNopadding className="col-12 node-Text">
                  {node.nickname}
                </ColNopadding>
              </Row>
            )} */}
            {/* {node.level === level1 && ( 
            <>*/}
            <Row className="row">
              <ColNopadding className="col-12 node-Text">
                <span className="truncate" title={node.email}>
                  {node.email}
                </span>
              </ColNopadding>
            </Row>
            {/* <Row className="row">
              <ColNopadding className="col-12 node-Text">
                <span title={node.rank}>{node.rank}</span>
              </ColNopadding>
            </Row> */}
            {/*</>
             )} */}
          </ColNopadding>
        </Row>
      </NodeContainer>
      {node.level % 2 === levelArrows && visited === false && (
        <Row className="row">
          <ColArrow className="col-12 text-center">
            <Arrow
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/arrow-down.png`}
              alt="more nodes"
              data-get-more="moreNodes"
            ></Arrow>
          </ColArrow>
        </Row>
      )}
    </div>
  );
};

TreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default TreeNode;
