import React from 'react';
import PropTypes from 'prop-types';
import NewsView from '../../../../components/administration/news/newsView';
import Layout from '../../../../components/layouts/verticalLayout';

const NewsViewPage = (props) => {
  return (
    <Layout
      body={
        <>
          <NewsView uuid={props.uuid} />
        </>
      }
    />
  );
};

NewsViewPage.propTypes = {
  uuid: PropTypes.string,
};

export default NewsViewPage;
