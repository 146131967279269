import * as Yup from 'yup';

export const dataModel = {
  name: '',
  surname: '',
  role: '',
  email: '',
  nationality: '',
};

export const validationDataModel = () => {
  return Yup.object().shape({
    name: Yup.string().required('forms.validations.required'),
    surname: Yup.string().required('forms.validations.required'),
    role: Yup.string().required('forms.validations.required'),
    email: Yup.string()
      .required('forms.validations.required')
      .email('forms.validations.email'),
    nationality: Yup.string().required('forms.validations.required'),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
