import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';

import {
  InputTextFormik,
  InputTextareaFormik,
  WysiwygFormik,
  InputSelectFormik,
} from '@smartups-sl/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './newsView.data.form';
import { PLATFORMS, LANGUAGES } from '../../../../constants/index';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';
import { ImageView } from './newsView.style';

const NewsForm = (props) => {
  const { t, onback, i18n, newValues } = props;

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <div className="row">
            <div className="col-12">
              <Title type="h3">{t('forms.news.titleDetail')}</Title>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="title"
                className="form-control"
                labelField={t('forms.news.labels.title')}
                name="title"
                component={InputTextFormik}
                fieldType="text"
                placeholder=""
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="body"
                labelField={t('forms.news.labels.body')}
                name="body"
                component={WysiwygFormik}
                mandatory
                saveAsHtml
                toolbarOptions={{
                  options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign'],
                  inline: {
                    inDropdown: false,
                    options: ['bold', 'italic', 'underline'],
                  },
                }}
                placeholder=""
                toolbarHidden
                locale={i18n.language}
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="resume"
                className="form-control-textArea"
                labelField={t('forms.news.labels.resume')}
                name="resume"
                component={InputTextareaFormik}
                placeholder=""
                rows={5}
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="author"
                className="form-control"
                labelField={t('forms.news.labels.author')}
                name="author"
                component={InputTextFormik}
                fieldType="text"
                placeholder=""
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="platform"
                labelField={t('forms.news.labels.platform')}
                name="platform"
                component={InputSelectFormik}
                placeholder=""
                optionLabel="label"
                optionValue="code"
                options={PLATFORMS.map((item) => ({
                  ...item,
                  label: t(item.label),
                }))}
                classNamePrefix="dark"
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                id="lang"
                labelField={t('forms.news.labels.lang')}
                name="lang"
                component={InputSelectFormik}
                fieldType="text"
                placeholder=""
                optionLabel="label"
                optionValue="code"
                options={LANGUAGES.map((item) => ({
                  ...item,
                  label: t(item.label),
                }))}
                isMultiple
                classNamePrefix="dark"
                readOnly
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <div className="labelDiv undefined no-padding">
                  <label className="control-label">{t('forms.news.labels.image')}</label>
                </div>
                {newValues?.imageUrl ? (
                  <div className="preview-noData-avatar">
                    <ImageView src={newValues?.imageUrl}></ImageView>
                  </div>
                ) : (
                  <div className="preview-noData-avatar">No data</div>
                )}
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
              <Button
                type="button"
                className="btn btn-lg"
                variant="secondary"
                onClick={() => onback()}
              >
                {t('forms.user.buttons.back')}
              </Button>
            </ButtonsContainer>
          </div>
        </div>
      </div>
    </>
  );
};

NewsForm.propTypes = {
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  newValues: PropTypes.object,
};

const NewsFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'NewsForm',
})(withTranslation()(NewsForm));

export default NewsFormF;
