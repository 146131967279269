import styled from 'styled-components';

export const ContainerLabel = styled.div`
  border-bottom: 1px solid #12374e;
  background: #12374e;
  color: #fff;
  padding: 6px 15px;
`;

export const ContainerTextValue = styled.div`
  border-bottom: 1px solid #12374e;
  padding: 10px 15px;
  font-weight: bold;
  color: #12374e;
`;
