import { createAction } from 'redux-actions';
import { apiPut, apiDelete, apiGet } from '../../api/index';
import { urlNewsToPublish, urlNews, urlNewsPublish } from '../../api/urls';
import { FILTER_NEWS, PAGE_SIZE_10 } from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize, sort }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.title) filter += '&title=' + filters.title;
    if (filters.status) filter += '&status=' + filters.status;
    if (filters.platform) filter += '&platform=' + filters.platform;
  }

  if (sort) filter += '&sort=' + sort;

  return filter;
};

/**
 * Filter list users.
 */
export const filterNews = createAction(FILTER_NEWS, (filter, param) =>
  apiGet(urlNews + generateFilterQuery(filter), param, true)()
);

/**
 * Update BotProvider.
 */
export const toPublishNews = createAction('TO_PUBLISH', (param) =>
  apiPut(urlNewsToPublish, undefined, param, true)()
);

/**
 * Update BotProvider.
 */
export const publishNews = createAction('PUBLISH', (param) =>
  apiPut(urlNewsPublish, undefined, param, true)()
);

/**
 * Delete user by uuid.
 */
export const deleteNews = createAction('DELETE_NEWS', (param) => apiDelete(urlNews, param, true)());
