import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
// import { CSSTransition } from 'react-transition-group';
import * as AuthService from '../../../../services/AuthService';
import PATHS from '../../../../constants/paths';
// import { Button } from '@smartups-sl/react-component-library';
// import Menu, { Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { IconStyled, ButtonOption, Logo, OffCanvasHeader, NavLink } from './styled';
// import { showDialog } from '@smartups-sl/react-utils-components';
import { Can } from '../../../permissions/Can';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  handlerSelectOption = async (key) => {
    // const { nickname, t } = this.props;

    switch (key) {
      case 'home':
        let route = PATHS.ADMIN_HOME;
        this.props.history.push(route);
        break;
      case 'close_session':
        await AuthService.logout();
        this.props.history.push(PATHS.LOGIN);
        break;
      case 'settings':
        this.props.history.push(PATHS.MY_PROFILE);
        break;
      case 'users':
        this.props.history.push(PATHS.USER_LIST);
        break;
      case 'users_review':
        this.props.history.push(PATHS.USER_LIST_REVIEW);
        break;
      case 'adminTree':
        this.props.history.push(PATHS.ADMIN_TREE_SIMPLE + '/root');
        break;
      case 'employers':
        this.props.history.push(PATHS.EMPLOYER_LIST);
        break;
      case 'news':
        this.props.history.push(PATHS.NEWS_LIST);
        break;
      case 'marketing':
        this.props.history.push(PATHS.MARKETING_LIST);
        break;
      case 'permissions':
        this.props.history.push(PATHS.PERMISSIONS);
        break;
      default:
        break;
    }
  };
  
  render() {
    const { t, offCanvas } = this.props;

    return (
      <>
        <ul className="nav flex-column text-white w-100">
          {offCanvas && (
            <OffCanvasHeader className="offcanvas-header">
              {/* <h5 className="offcanvas-title" id="offcanvasExampleLabel"></h5> */}
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </OffCanvasHeader>
          )}
          <a
            href={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}${
              process.env.REACT_APP_PORT ? `:${process.env.REACT_APP_PORT}` : ''
            }/client/home`}
            className="nav-link my-2 text-center"
          >
            <Logo
              src={`/assets/themes/${process.env.REACT_APP_THEME}/images/logo_white.png`}
              alt="logo"
              className="logo"
              offCanvas={offCanvas}
            />
          </a>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('home');
                }}
              >
                <IconStyled className="fas fa-home" />
                {t('home')}
              </ButtonOption>
            </span>
          </NavLink>
          <Can do={USER_PERMISSIONS.ADMIN_TREE}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('adminTree');
                  }}
                >
                  <IconStyled className="fas fa-sitemap" />
                  {t('adminTree')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <Can do={USER_PERMISSIONS.USER_TREE_VIEW_LIMITED}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('adminTree');
                  }}
                >
                  <IconStyled className="fas fa-sitemap" />
                  {t('adminTree')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <Can do={USER_PERMISSIONS.USER_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('users');
                  }}
                >
                  <IconStyled className="fas fa-users" />
                  {t('users')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <Can do={USER_PERMISSIONS.USER_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('users_review');
                  }}
                >
                  <IconStyled className="fas fa-search" />
                  {t('users_review')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <Can do={USER_PERMISSIONS.EMPLOYEE_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('employers');
                  }}
                >
                  <IconStyled className="fas fa-user-tie" />
                  {t('employers')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <Can do={USER_PERMISSIONS.NEWS_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('news');
                  }}
                >
                  <IconStyled className="fas fa-newspaper" />
                  {t('news')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          {/* <Can do={USER_PERMISSIONS.NEWS_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('marketing');
                  }}
                >
                  <IconStyled className="far fa-folder-open" />
                  {t('marketing')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can> */}
          <Can do={USER_PERMISSIONS.ROLES_LIST}>
            <NavLink href="#" className="nav-link">
              <span className="mx-2">
                <ButtonOption
                  onClick={() => {
                    this.handlerSelectOption('permissions');
                  }}
                >
                  <IconStyled className="fas fa-user-lock" />
                  {t('rolesPermission')}
                </ButtonOption>
              </span>
            </NavLink>
          </Can>
          <NavLink href="#" className="nav-link">
            <span className="mx-2">
              <ButtonOption
                onClick={() => {
                  this.handlerSelectOption('settings');
                }}
              >
                <IconStyled className="fas fa-user-cog" />
                {t('settings')}
              </ButtonOption>
            </span>
          </NavLink>
        </ul>
        <span href="#" className="nav-link w-100 mb-2">
          <ButtonOption
            onClick={() => {
              this.handlerSelectOption('close_session');
            }}
          >
            <IconStyled className="fas fa-power-off" />
            {t('close_session')}
          </ButtonOption>
        </span>
      </>
    );
  }
}

Sidebar.propTypes = {
  nickname: PropTypes.string,
  offCanvas: PropTypes.bool,
};

export default withRouter(withTranslation()(Sidebar));
