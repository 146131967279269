export const USER_PERMISSIONS = {
  /* GENERATOR_INJECT_DOMAIN */
  EMPLOYEE_LIST: 'EMPLOYEE_LIST',
  EMPLOYEE_CREATE: 'EMPLOYEE_CREATE',
  EMPLOYEE_UPDATE: 'EMPLOYEE_UPDATE',
  EMPLOYEE_DELETE: 'EMPLOYEE_DELETE',
  EMPLOYEE_GET: 'EMPLOYEE_GET',
  ROLES_LIST: 'ROLES_LIST',
  ROLES_CREATE: 'ROLES_CREATE',
  ROLES_UPDATE: 'ROLES_UPDATE',
  ROLES_DELETE: 'ROLES_DELETE',
  ROLES_GET: 'ROLES_GET',
  STATUS_UPDATE: 'STATUS_UPDATE',
  USER_GET: 'USER_GET',
  USER_LIST: 'USER_LIST',
  USER_CREATE: 'USER_CREATE',
  USER_UPDATE: 'USER_UPDATE',
  USER_DELETE: 'USER_DELETE',
  USER_TREE_VIEW: 'USER_TREE_VIEW',
  USER_TREE_VIEW_LIMITED: 'USER_TREE_VIEW_LIMITED',
  USER_BLOCK: 'USER_BLOCK',
  USER_RESET_TFA_UPDATE: 'USER_RESET_TFA_UPDATE',
  USER_DASHBOARD_VIEW: 'USER_DASHBOARD_VIEW',
  ADMIN_TREE: 'ADMIN_TREE',
  USER_BLOCK_GET: 'USER_BLOCK_GET',
  USER_BLOCK_LIST: 'USER_BLOCK_LIST',
  USER_BLOCK_UPDATE: 'USER_BLOCK_UPDATE',
  NEWS_LIST: 'NEWS_LIST',
  NEWS_CREATE: 'NEWS_CREATE',
  NEWS_CREATE_FIDELITY: 'NEWS_CREATE_FIDELITY',
  NEWS_CREATE_SMARTNET: 'NEWS_CREATE_SMARTNET',
  NEWS_PUBLISH: 'NEWS_PUBLISH',
  NEWS_DELETE: 'NEWS_DELETE',
  KYC_RESET: 'KYC_RESET',
};

// // INIT NOTIFICATION PERMISSIONS
// can(ACTION.VIEW, DOMAIN.NOTIFICATION);
// can(ACTION.LIST, DOMAIN.NOTIFICATION);
// can(ACTION.CREATE, DOMAIN.NOTIFICATION);
// can(ACTION.UPDATE, DOMAIN.NOTIFICATION);
// can(ACTION.DELETE, DOMAIN.NOTIFICATION);
// can(ACTION.EXECUTE, DOMAIN.NOTIFICATION);
// // END NOTIFICATION PERMISSIONS

/* GENERATOR_INJECT_PERMISSSIONS */
