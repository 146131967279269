import React from 'react';
import List from '../../../../components/administration/news/newsFilterList';
import Layout from '../../../../components/layouts/verticalLayout';

const NewsListPage = (props) => {
  return (
    <Layout
      body={
        <>
          <List />
        </>
      }
    />
  );
};

NewsListPage.propTypes = {};

export default NewsListPage;
