import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';
import moment from 'moment-timezone';
import { ContainerLabel, ContainerTextValue } from './userBlockReviewForm.style';

const UserReviewForm = (props) => {
  const { data, action } = props;
  const { t } = useTranslation();

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 mb-2">
        <div className="row">
          <div className="col-12 marg-btm-1rem marg-top-40px">
            <Title type="h3" variant="secondary">
              {t('forms.user.titleBlockReviewDetails')}
            </Title>
          </div>
        </div>
        {data && (
          <>
            <div className="row">
              <div className="col-12 mb-2">
                <Title type="h5" variant="secondary">
                  {t('forms.user.dataUser')}
                </Title>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-lg-4">
                <div className="row ">
                  <div className="col-12 ">Nombre: {data.userBlock.name}</div>
                  <div className="col-12 ">Apellidos: {data.userBlock.surname}</div>
                  <div className="col-12 ">Email: {data.userBlock.email}</div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div className="row ">
                  <div className="col-12 ">Platform: {data.platform}</div>
                  <div className="col-12 ">Message: {data.message}</div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons mt-5">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => action('cancel')}
                >
                  {t('forms.user.buttons.back')}
                </Button>
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="primary"
                  onClick={() => action('block')}
                >
                  {t('forms.user.buttons.blockConfirm')}
                </Button>
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="primary"
                  onClick={() => action('notBlocked')}
                >
                  {t('forms.user.buttons.notBlocked')}
                </Button>
              </ButtonsContainer>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

UserReviewForm.propTypes = {
  data: PropTypes.object,
  action: PropTypes.func,
};

export default UserReviewForm;
