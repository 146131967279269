import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Languages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      languages: [],
      selected: undefined,
    };
  }

  componentDidMount() {
    this.changeLanguage(this.props.i18n.language === 'es-ES' ? 'es' : this.props.i18n.language);
  }

  changeLanguage = (lang) => {
    this.props.i18n.changeLanguage(lang);
    this.setState({
      languages: this.props.i18n.languages ? this.props.i18n.languages : ['es', 'en'],
      selected: lang,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="dropdown select-languages">
        <button
          className="btn btn-sm dropdown-toggle language-item"
          type="button"
          data-toggle="dropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          id="dropdownMenuButton"
        >
          <img
            src={`/assets/images/${this.state.selected}.png`}
            alt={`language.languages.${this.state.selected}`}
          />
          {this.state.selected
            ? t(`language.languages.${this.state.selected}`)
            : t('language.select_option')}
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          {this.state.languages && this.state.languages.length > 0 && (
            <>
              {this.state.languages.map((lang) => {
                return (
                  <button
                    key={lang}
                    className="dropdown-item language-item"
                    onClick={() => {
                      this.changeLanguage(lang);
                    }}
                  >
                    <img src={`/assets/images/${lang}.png`} alt={`language.languages.${lang}`} />
                    {t(`language.languages.${lang}`)}
                  </button>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Languages);
