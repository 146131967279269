import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import * as AdminServices from '../../../../services/AdminService';
import * as UserService from '../../../../services/UserService';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import { PAGE_SIZE_10 } from '../../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
import { LinkActionStyled } from './userList.styled';
import { PaginateList } from '@smartups-sl/react-complex-components';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { Can } from '../../../permissions/Can';
import { connect } from 'react-redux';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';

class UserListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      countries: null,
      totals: undefined,
    };
  }

  componentDidMount() {
    GeneralServices.getAllNationalities().then((result) => {
      this.setState({ countries: result });
    });
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    return [
      {
        Header: this.getHeaderTable('forms.user.table.heads.nickname'),
        accessor: 'nickname',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.name'),
        accessor: 'name',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.surname'),
        accessor: 'surname',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.email'),
        accessor: 'email',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.country'),
        accessor: 'nationality',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.nationality && this.state.countries) {
            const pais = this.state.countries.find(
              (country) => country.uuid === row.original.nationality
            );
            if (pais) {
              return pais.countryName;
            }
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.tokens'),
        accessor: 'pendingQwarks',
        className: 'text-center',
        width: 100,
        Cell: (row) => {
          if (row.original.pendingQwarks) {
            return row.original.pendingQwarks;
          }
          return '-';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.bullrunPendingRefund'),
        accessor: 'bullrunPendingRefund',
        className: 'text-center',
        width: 100,
        Cell: (row) => {
          if (row.original.bullrunPendingRefund) {
            return <i className="fas fa-check confirm"></i>;
          }
          return <i className="fas fa-times declined"></i>;
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.active'),
        accessor: 'active',
        className: 'text-center',
        width: 60,
        Cell: (row) => {
          if (row.original.active) {
            return <i className="fas fa-check confirm"></i>;
          }
          return <i className="fas fa-times declined"></i>;
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.emailVerified'),
        accessor: 'emailVerified',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.emailVerified) {
            return <i className="fas fa-check confirm"></i>;
          }
          return <i className="fas fa-times declined"></i>;
        },
      },
      {
        Header: this.getHeaderTable('forms.user.table.heads.passedKYC'),
        accessor: 'passedKYC',
        className: 'text-center',
        width: 60,
        Cell: (row) => {
          if (row.original.passedKYC === 'approved') {
            return <i className="fas fa-check confirm"></i>;
          } else if (row.original.passedKYC === 'declined') {
            return <i className="fas fa-times declined"></i>;
          } else if (row.original.passedKYC === 'pending') {
            return <i className="fas fa-check pending"></i>;
          }

          return <i className="fas fa-search"></i>;
        },
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 120,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <Can do={USER_PERMISSIONS.USER_TREE_VIEW}>
            <LinkActionStyled
              to={`/adm/administration/user/tree/${obj.original.uuid}`}
              type="routerDom"
              variant="secondary"
            >
              <i className="fas fa-project-diagram" title="Ver árbol"></i>
            </LinkActionStyled>
          </Can>
          <Can do={USER_PERMISSIONS.USER_DASHBOARD_VIEW}>
            <LinkActionStyled
              variant="secondary"
              onClick={() => this.handlerGoToClientView(obj.original.uuid)}
            >
              <i className="fas fa-eye" title="Vista cliente" />
            </LinkActionStyled>
          </Can>
          <Can do={USER_PERMISSIONS.KYC_RESET}>
            {obj.original.passedKYC === 'declined' && (
              <LinkActionStyled
                variant="primary"
                onClick={() => this.handlerResetKyc(obj.original.uuid)}
              >
                <i className="fas fa-redo" title="KYC Reset"></i>
              </LinkActionStyled>
            )}
          </Can>
        </div>
      </>
    );
  };

  handlerResetKyc = async (uuid) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await UserService.resetKyc(uuid);
      if (result && result.uuid) {
        showDialog(
          this.props.t('forms.user.messages.kycReset.title'),
          this.props.t('forms.user.messages.kycReset.success'),
          'success',
          false,
          'OK',
          false
        );

        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.user.dialog.resetKyc.title'),
      t('forms.user.dialog.resetKyc.text'),
      'warning',
      true,
      t('forms.user.dialog.resetKyc.buttonOk'),
      false,
      okAction,
      t('forms.user.dialog.resetKyc.buttonNo')
    );
  };

  handlerGoToClientView = async (uuid) => {
    const result = await UserService.getUserToken(uuid);
    if (result && result.data?.token) {
      window
        .open(`${process.env.REACT_APP_IMPERSONATE_LOGIN}/${result.data.token}`, '_blank')
        .focus();
    }
  };

  toList = async (filter) => {
    const { setFilters, filtersSaved, backFilter } = this.props;
    if (backFilter) {
      setFilters(filtersSaved.filters);
      filter = filtersSaved;
    }
    const result = await UserService.listFilterUser(filter);
    this.setState({ totals: result?.totalDocs || 0 });
    GeneralServices.setFilterBack(false);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    const { totals } = this.state;
    return (
      <>
        <div className={className || ''}>
          <PaginateList
            title={t('forms.user.titleList')}
            defaultPageSize={PAGE_SIZE_10}
            columns={this.getColumns()}
            toList={this.toList}
            filters={filters}
            forceRefresh={this.state.refreshData}
            iconAdd="fas fa-plus"
            variantTitle="secondary"
            classNameTable="payper-table"
          ></PaginateList>
        </div>
        <div className="row mt-4 mb-5">
          <div className="col-12 text-center">
            {`${t('forms.user.totals')}: `}
            <strong>{`${totals}`}</strong>
          </div>
        </div>
      </>
    );
  }
}

UserListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  setFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filtersSaved: getFilterSelector(state),
  backFilter: getBackFilterSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(UserListContainer));
