import * as Yup from 'yup';

export const dataModel = {
  title: '',
  body: '',
  resume: '',
  author: '',
  platform: '',
  lang: [],
  image: '',
};

export const validationDataModel = () => {
  return Yup.object().shape({
    title: Yup.string()
      .required('forms.validations.required')
      .max(150, {
        message: 'forms.validations.maxLength',
        args: { max: 150 },
      }),
    body: Yup.string().required('forms.validations.required'),
    resume: Yup.string()
      .required('forms.validations.required')
      .max(300, {
        message: 'forms.validations.maxLength',
        args: { max: 300 },
      }),
    author: Yup.string()
      .required('forms.validations.required')
      .max(300, {
        message: 'forms.validations.maxLength',
        args: { max: 300 },
      }),
    platform: Yup.string().required('forms.validations.required'),
    lang: Yup.array()
      .required('forms.validations.required')
      .min(1, {
        message: 'forms.validations.maxLength',
        args: { min: 1 },
      }),
  });
};

export const setInitialValues = (initialValues) => {
  const newValues = { ...dataModel };
  if (initialValues) {
    const items = Object.keys(newValues);
    items.forEach((item) => {
      if (initialValues[item]) {
        newValues[item] = initialValues[item];
      }
    });
    return newValues;
  }

  return dataModel;
};
