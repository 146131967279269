export const urlBase = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/api/`;

/** AUTH **/
export const urllogin = `${urlBase}v1/adm/auth/login`;
export const urlauthProfile = `${urlBase}v1/adm/employer/profile`;
export const urlForgotPassword = `${urlBase}v1/adm/auth/forgot`;
export const urlRecoveryPass = `${urlBase}v1/adm/auth/recovery`;
export const urlAvatar = `${urlBase}v1/auth/profile/avatar`;
export const urlauthChangePass = `${urlBase}v1/adm/employer/profile/password`;

/** USER **/
export const urlGetAvatar = `${urlBase}v1/users/{param}/avatar`;
export const urlGetClientToken = `${urlBase}v1/adm/user/impersonate`;

/** GLOBALS **/
export const urlGetNationalities = `${urlBase}v1/countries`;

/** ADMIN **/
export const urlFilterUser = `${urlBase}v1/adm/user/filter`;
export const urlDelUser = `${urlBase}v1/adm/user`;
export const urlGetUser = `${urlBase}v1/adm/user`;
export const urlUpdateUser = `${urlBase}v1/adm/user`;
export const urlResetTFAUser = `${urlBase}v1/adm/user/tfa/reset`;
export const urlRecoveryTFA = `${urlBase}v1/adm/user/tfa/recovery`;
export const urlVerifyTwoFA = `${urlBase}v1/adm/user/tfa/verify`;
export const urlFilterUserReview = `${urlBase}v1/adm/user/filter/review`;
export const urlFilterUserBlockRequest = `${urlBase}v1/adm/userBlockRequest-s`;
export const urlFilterUserDuplicated = `${urlBase}v1/adm/user/duplicated`;
export const urlPutUserDuplicated = `${urlBase}v1/adm/user/statusDuplicated`;

export const urlGetunilevelTree = `${urlBase}v1/adm/tree/unilevel`;
export const urlGetAdminunilevelTree = `${urlBase}v1/adm/tree/unilevel/all`;

export const urlNews = `${urlBase}v1/adm/news`;
export const urlNewsToPublish = `${urlBase}v1/adm/news/toPublish`;
export const urlNewsPublish = `${urlBase}v1/adm/news/publish`;
export const urlMarketing = `${urlBase}v1/adm/marketingResources-s`;

export const urlKycResetUser = `${urlBase}v1/adm/user/kyc/reset`;
// export const urlUserBlockRequest = `${urlBase}v1/adm/userBlockRequest-s`;
/* GENERATOR_INJECT_URLS */
