const dataColors = {
  primary: '#26abe1',
  primaryLight: '#3bc2f8',
  secondary: '#12374e',
  fourthy: '#FF8F36',
  terciary: '#E83C7E',
  terciaryLight: '#ff438b',
  white: '#fff',
};

const theme = {
  colors: {
    primary: dataColors.primary,
    primaryLight: dataColors.primaryLight,
    secondary: dataColors.secondary,
    fourthy: dataColors.fourthy,
    terciary: dataColors.terciary,
    terciaryLight: dataColors.terciaryLight,
    white: dataColors.white,
  },
  paginations: {
    buttons: {
      disabled: '#fff',
      color: '#fff',
      hover: '#c5ad00',
      align: 'center',
    },
  },
  notifications: {
    personal: {
      backgroundColor: '#c5ad00',
      backgroundColorHover: '#c5ad00',
    },
    general: {
      backgroundColor: '#c5ad00',
      backgroundColorHover: '#c5ad00',
    },
  },
  sidebar: {
    colorLink: dataColors.white,
    colorLinkHover: dataColors.white,
    backgroundColor: dataColors.secondary,
  },
  body: {
    backgroundColor: '#fff',
  },
  header: {
    backgroundColor: dataColors.secondary,
    borderColor: dataColors.primary,
  },
  buttons: {
    default: {
      backgroundColor: '#fff',
      backgroundColorHover: '#fff',
      color: '#000',
      colorHover: '#000',
      borderColor: '#000',
      borderColorHover: '#000',
    },
    primary: {
      backgroundColor: dataColors.primary,
      backgroundColorHover: dataColors.primaryLight,
      color: dataColors.white,
      colorHover: dataColors.white,
      borderColor: dataColors.primary,
      borderColorHover: dataColors.primaryLight,
    },
    secondary: {
      backgroundColor: '#12374e',
      backgroundColorHover: '#1f1f1f',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#12374e',
      borderColorHover: '#545454',
    },
    terciary: {
      backgroundColor: dataColors.terciary,
      backgroundColorHover: dataColors.terciaryLight,
      color: dataColors.white,
      colorHover: dataColors.white,
      borderColor: dataColors.terciary,
      borderColorHover: dataColors.terciaryLight,
    },
    alert: {
      backgroundColor: '#d00000',
      backgroundColorHover: '#e80000',
      color: '#fff',
      colorHover: '#fff',
      borderColor: '#d00000',
      borderColorHover: '#e80000',
    },
    trasparent: {
      backgroundColor: 'trasparent',
      backgroundColorHover: 'trasparent',
      color: '#fff',
      colorHover: '#fff',
      borderColor: 'trasparent',
      borderColorHover: 'trasparent',
    },
  },
  links: {
    settings: {
      active: dataColors.secondary,
      default: dataColors.primary,
      hover: '#1d95c5',
    },
    default: {
      color: '#000',
      colorHover: '#000',
    },
    secondary: {
      color: dataColors.terciary,
      colorHover: dataColors.primary,
    },
    terciary: {
      color: dataColors.terciary,
      colorHover: dataColors.primary,
    },
    primary: {
      color: '#e10a41',
      colorHover: '#e10a41',
    },
  },
  text: {
    align: 'justify',
  },
  title: {
    h1: {
      size: '2rem',
      family: 'Gotham',
    },
    h2: {
      size: '1.8rem',
      family: 'Gotham',
    },
    h3: {
      size: '1.6rem',
      family: 'Gotham',
    },
    h4: {
      size: '1.4rem',
      family: 'Gotham',
    },
    h5: {
      size: '1.2rem',
      family: 'Gotham',
    },
  },
  fonts: {
    family: 'Gotham',
    size: {
      sm: '0.7rem',
      md: '0.875rem',
      lg: '1rem',
      xlg: '1.5rem',
      xxlg: '2rem',
    },
  },
};

export default theme;
