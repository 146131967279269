import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import NewsView from './newsView.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';

class NewsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: null,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getNews(this.props.uuid).then((result) => {
        if (result?.uuid) {
          this.setState({ values: result, edit: true });
        }
      });
    }
  }

  handleOnback = () => {
    this.props.history.push(PATHS.NEWS_LIST);
  };

  render() {
    return (
      <NewsView
        initialValues={this.state.values}
        onback={this.handleOnback}
        newValues={this.state.values}
      />
    );
  }
}

NewsContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(NewsContainer));
