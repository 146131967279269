import { USER_PERMISSIONS } from '../components/permissions/rolesPermission';

//CONSTANTS
export const PAGE_SIZE_10 = 10;
export const PAGE_SIZE_25 = 25;
export const PAGE_SIZE_50 = 50;

// USER ROLE
export const ROLE_ADM = 'admin';
export const ROLE_DEV = 'dev';
export const ROLE_SUPPORT = 'support';
export const ROLE_TRADER = 'trader';
export const CONTENT_MANAGER = 'content-manager';
export const CONTENT_PUBLISHER = 'content-publisher';
export const LEADER = 'leader';

export const ROLES = [
  { label: 'roles.adm', value: ROLE_ADM },
  { label: 'roles.dev', value: ROLE_DEV },
  { label: 'roles.support', value: ROLE_SUPPORT },
  { label: 'roles.trader', value: ROLE_TRADER },
  { label: 'roles.contentManager', value: CONTENT_MANAGER },
  { label: 'roles.contentPublisher', value: CONTENT_PUBLISHER },
  { label: 'roles.leader', value: LEADER },
];

// ACTIONS AUTH
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CLEAN_AUTH = 'CLEAN_AUTH';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SET_AUTH_PROFILE = 'SET_AUTH_PROFILE';
export const FORGOT_PASS = 'FORGOT_PASS';
export const RECOVERY_PASS = 'RECOVERY_PASS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const GET_AVATAR = 'GET_AVATAR';
export const SET_FILTER = 'SET_FILTER';
export const CLEAN_FILTERS = 'CLEAN_FILTERS';
export const SET_BACK = 'SET_BACK';
export const GET_CLIENT_TOKEN = 'GET_CLIENT_TOKEN';
export const SET_PEM_KEY = 'SET_PEM_KEY';

// ACTIONS USER
export const USER_INFO = 'USER_INFO';
export const CLEAN_USER = 'CLEAN_USER';

// GLOBALS ACTIONS
export const GET_NATIONALITIES = 'GET_NATIONALITIES';

// ADMIN ACTIONS
export const GET_UNILEVEL_TREE = 'GET_UNILEVEL_TREE';

export const FILTER_USERS = 'FILTER_USERS';
export const GET_USER = 'GET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const CREATE_USER = 'CREATE_USER';
export const RESET_TFA_USER = 'RESET_TFA_USER';
export const RECOVERY_TFA_USER = 'RECOVERY_TFA_USER';
export const VERIFY_2FA = 'VERIFY_2FA';
export const FILTER_MARKETING = 'FILTER_MARKETING';
export const CREATE_RESOURCE = 'CREATE_RESOURCE';
export const GET_RESOURCE = 'GET_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';

export const ENE = { code: '0', label: 'months.ene' };
export const FEB = { code: '1', label: 'months.feb' };
export const MAR = { code: '2', label: 'months.mar' };
export const ABR = { code: '3', label: 'months.abr' };
export const MAY = { code: '4', label: 'months.may' };
export const JUN = { code: '5', label: 'months.jun' };
export const JUL = { code: '6', label: 'months.jul' };
export const AGO = { code: '7', label: 'months.ago' };
export const SEP = { code: '8', label: 'months.sep' };
export const OCT = { code: '9', label: 'months.oct' };
export const NOV = { code: '10', label: 'months.nov' };
export const DIC = { code: '11', label: 'months.dic' };

export const MESES = [ENE, FEB, MAR, ABR, MAY, JUN, JUL, AGO, SEP, OCT, NOV, DIC];

export const ENE_SERVER = { code: '1', label: 'months.ene' };
export const FEB_SERVER = { code: '2', label: 'months.feb' };
export const MAR_SERVER = { code: '3', label: 'months.mar' };
export const ABR_SERVER = { code: '4', label: 'months.apr' };
export const MAY_SERVER = { code: '5', label: 'months.may' };
export const JUN_SERVER = { code: '6', label: 'months.jun' };
export const JUL_SERVER = { code: '7', label: 'months.jul' };
export const AGO_SERVER = { code: '8', label: 'months.ago' };
export const SEP_SERVER = { code: '9', label: 'months.sep' };
export const OCT_SERVER = { code: '10', label: 'months.oct' };
export const NOV_SERVER = { code: '11', label: 'months.nov' };
export const DIC_SERVER = { code: '12', label: 'months.dic' };

export const MESES_SERVER = [
  ENE_SERVER,
  FEB_SERVER,
  MAR_SERVER,
  ABR_SERVER,
  MAY_SERVER,
  JUN_SERVER,
  JUL_SERVER,
  AGO_SERVER,
  SEP_SERVER,
  OCT_SERVER,
  NOV_SERVER,
  DIC_SERVER,
];

export const LANG_SPANISH = { code: 'es', label: 'language.languages.es' };
export const LANG_ENGLISH = { code: 'en', label: 'language.languages.en' };

export const LANGUAGES = [LANG_SPANISH, LANG_ENGLISH];

export const FILTER_NEWS = 'FILTER_NEWS';
export const GET_NEWS = 'GET_NEWS';
export const UPDATE_NEWS = 'UPDATE_NEWS';
export const DELETE_NEWS = 'DELETE_NEWS';
export const CREATE_NEWS = 'CREATE_NEWS';

export const PLATFORM_SMARTNET = {
  code: 'smartnet',
  label: 'Smartnet',
  permmission: [USER_PERMISSIONS.NEWS_CREATE, USER_PERMISSIONS.NEWS_CREATE_SMARTNET],
};
export const PLATFORM_FIDELITY = {
  code: 'fidelitybots',
  label: 'FidelityBots',
  permmission: [USER_PERMISSIONS.NEWS_CREATE, USER_PERMISSIONS.NEWS_CREATE_FIDELITY],
};

export const PLATFORMS = [PLATFORM_SMARTNET, PLATFORM_FIDELITY];

export const NEWS_STATUS_DRAFT = {
  code: 'DRAFT',
  label: 'forms.news.status.draft',
};
export const NEWS_STATUS_TO_PUBLISH = {
  code: 'TO_PUBLISH',
  label: 'forms.news.status.to_publish',
};
export const NEWS_STATUS_PUBLISHED = {
  code: 'PUBLISHED',
  label: 'forms.news.status.published',
};
export const NEWS_STATUS_DELETED = {
  code: 'DELETED',
  label: 'forms.news.status.deleted',
};

export const NEWS_STATUS = [
  NEWS_STATUS_DRAFT,
  NEWS_STATUS_TO_PUBLISH,
  NEWS_STATUS_PUBLISHED,
  NEWS_STATUS_DELETED,
];

export const CATEGORY_INFO = {
  code: 'INFOR',
  label: 'documents_categories.info',
};
export const CATEGORY_MARK = {
  code: 'MARKETING',
  label: 'documents_categories.mark',
};

export const DOCUMENTS_CATEGORIES = [CATEGORY_INFO, CATEGORY_MARK];

export const KYC_STATUS_APPROVED = {
  code: 'approved',
  label: 'kyc.approved',
};
export const KYC_STATUS_DECLINED = {
  code: 'declined',
  label: 'kyc.declined',
};
export const KYC_STATUS_NEED_REVIEW = {
  code: 'need_review',
  label: 'kyc.need_review',
};
export const KYC_STATUS_PENDING = {
  code: 'pending',
  label: 'kyc.pending',
};

export const ACTIVE = {
  code: 'true',
  label: 'userStatus.active',
};
export const NO_ACTIVE = {
  code: 'false',
  label: 'userStatus.noActive',
};

export const KYC_STATUS = [KYC_STATUS_PENDING, KYC_STATUS_APPROVED, KYC_STATUS_DECLINED];

export const USER_STATUS = [ACTIVE, NO_ACTIVE];
/* GENERATOR_INJECT_CONSTANTS */
