import { store } from '../store/index';
import {
  updateProfile,
  updateUserAvatar,
  getAvatar,
  filterUsers,
  getClientToken,
  filterUsersReview,
  updatePassword,
  filterRequestBlocked,
  resetKycUser,
} from '../store/actions/UserActions';
import { setFilters } from '../store/actions/GeneralActions';

/**
 * Update profile
 */
export const updateMyProfile = async (values) => {
  const objSend = {
    name: values.name,
    surname: values.surname,
  };
  const result = await store.dispatch(updateProfile(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Update avatar
 */
export const updateAvatar = async (image) => {
  const objSend = {
    avatar: image,
  };
  const result = await store.dispatch(updateUserAvatar(objSend));
  if (result && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * Get User avatar
 */
export const getUserAvatar = async (id) => {
  const objSend = [id];
  const result = await store.dispatch(getAvatar(objSend));
  if (result && result.payload) {
    return result.payload;
  }
  return null;
};

/**
 * List Users
 */
export const listFilterUser = async (filter) => {
  store.dispatch(setFilters(filter));
  const result = await store.dispatch(filterUsers(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Get client view token
 */
export const getUserToken = async (id) => {
  const objSend = [id];
  const result = await store.dispatch(getClientToken(objSend));
  if (result && result.payload) {
    return result.payload;
  }
  return null;
};

/**
 * Update employer password
 */
export const changePassword = async (values) => {
  const objSend = {
    password: values.password,
  };

  const result = await store.dispatch(updatePassword(objSend));
  if (result && result.payload && result.payload) {
    return 'OK';
  }
  return null;
};

/**
 * List Users pending Reviews
 */
export const listFilterUserReview = async (filter) => {
  store.dispatch(setFilters(filter));
  const result = await store.dispatch(filterUsersReview(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * List request block users
 */
export const listFilterBlockedRequest = async (filter) => {
  store.dispatch(setFilters(filter));
  const result = await store.dispatch(filterRequestBlocked(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Reset kyc
 */
export const resetKyc = async (id) => {
  const objSend = [id];
  const result = await store.dispatch(resetKycUser(objSend));
  if (result && result.payload) {
    return result.payload;
  }
  return null;
};
