import { store } from '../store/index';
import { toPublishNews, filterNews, publishNews, deleteNews } from '../store/actions/NewsActions';
import { setFilters } from '../store/actions/GeneralActions';

/**
 * List Users
 */
export const listFilterNews = async (filter) => {
  store.dispatch(setFilters(filter));
  const result = await store.dispatch(filterNews(filter));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * To publish news
 */
export const sendToPublish = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(toPublishNews(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Publish news
 */
export const publish = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(publishNews(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};

/**
 * Delete news
 */
export const sendDeleteNews = async (uuid) => {
  const param = [uuid];
  const result = await store.dispatch(deleteNews(param));
  if (result && result.payload && result.payload.data) {
    return result.payload.data;
  }
  return null;
};
