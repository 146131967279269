import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';

import {
  InputTextFormik,
  InputTextareaFormik,
  WysiwygFormik,
  InputImageFormik,
  InputSelectFormik,
} from '@smartups-sl/react-forms-components';

import { dataModel, validationDataModel, setInitialValues } from './newsForm.data.form';
import { PLATFORMS, LANGUAGES } from '../../../../constants/index';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';
import { AbilityContext } from '../../../permissions/Can';

const NewsForm = (props) => {
  const { t, handleSubmit, onback, i18n, edit, newValues } = props;
  const platforms = [];
  const context = useContext(AbilityContext);
  const [defaultImagePreview, setDefaultImagePreview] = useState(undefined);

  useEffect(() => {
    if (newValues && newValues.imageUrl) {
      const time = Date.now();
      setDefaultImagePreview(`${newValues.imageUrl}?test=${time}`);
    }
  }, [newValues]);

  for (const plat of PLATFORMS) {
    for (const permission of plat.permmission) {
      if (context.can(permission)) {
        platforms.push(plat);
        break;
      }
    }
  }

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <div className="row">
            <div className="col-12">
              <Title type="h3">{edit ? t('forms.news.titleEdit') : t('forms.news.titleNew')}</Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12">
                <Field
                  id="title"
                  className="form-control"
                  labelField={t('forms.news.labels.title')}
                  name="title"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="body"
                  labelField={t('forms.news.labels.body')}
                  name="body"
                  component={WysiwygFormik}
                  mandatory
                  saveAsHtml
                  toolbarOptions={{
                    options: [
                      'inline',
                      'blockType',
                      'fontSize',
                      'list',
                      'textAlign',
                      'link',
                      'image',
                    ],
                    inline: {
                      inDropdown: false,
                      options: ['bold', 'italic', 'underline'],
                    },
                  }}
                  placeholder=""
                  locale={i18n.language}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="resume"
                  className="form-control-textArea"
                  labelField={t('forms.news.labels.resume')}
                  name="resume"
                  component={InputTextareaFormik}
                  placeholder=""
                  rows={5}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="author"
                  className="form-control"
                  labelField={t('forms.news.labels.author')}
                  name="author"
                  component={InputTextFormik}
                  fieldType="text"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="platform"
                  labelField={t('forms.news.labels.platform')}
                  name="platform"
                  component={InputSelectFormik}
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={platforms.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  classNamePrefix="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="lang"
                  labelField={t('forms.news.labels.lang')}
                  name="lang"
                  component={InputSelectFormik}
                  fieldType="text"
                  placeholder=""
                  optionLabel="label"
                  optionValue="code"
                  options={LANGUAGES.map((item) => ({
                    ...item,
                    label: t(item.label),
                  }))}
                  isMultiple
                  classNamePrefix="dark"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Field
                  id="image"
                  className="avatar"
                  labelField={t('forms.news.labels.image')}
                  name="image"
                  component={InputImageFormik}
                  showPreview
                  singleImage
                  i18n={t}
                  withIcon={false}
                  withLabel={false}
                  buttonText={t('generic.image.choose')}
                  maxFileSize={1000000}
                  allowExtensions={['.jpg', '.jpeg', '.png']}
                  textFileSizeError={t('generic.image.tooBig')}
                  textFileTypeError={t('generic.image.noext')}
                  defaultPreviewWithURL
                  showMiniature
                  generateMiniature
                  widthMiniature={256}
                  heightMiniature={128}
                  defaultImagePreview={defaultImagePreview}
                  widthLimit={1024}
                  heightLimit={512}
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.user.buttons.back')}
                </Button>
                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.user.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

NewsForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  edit: PropTypes.bool,
  getImage: PropTypes.func,
  newValues: PropTypes.object,
};

const NewsFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: (props) => {
    return validationDataModel(props.edit);
  },
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'NewsForm',
})(withTranslation()(NewsForm));

export default NewsFormF;
