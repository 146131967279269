import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import * as AdminServices from '../../../../services/AdminService';
import * as UserService from '../../../../services/UserService';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import { PAGE_SIZE_10 } from '../../../../constants/index';
// import { showDialog } from '@smartups-sl/react-utils-components';
import { LinkActionStyled } from './userBlockReviewList.styled';
import { PaginateList } from '@smartups-sl/react-complex-components';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { Can } from '../../../permissions/Can';
import { connect } from 'react-redux';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import moment from 'moment-timezone';

class UserBlockReviewListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      countries: null,
    };
  }

  componentDidMount() {
    GeneralServices.getAllNationalities().then((result) => {
      this.setState({ countries: result });
    });
  }

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.user.tableBlock.heads.date'),
        accessor: 'createdAt',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.createdAt) {
            const dateForm = moment(row.original.createdAt);
            return dateForm.tz(moment.tz.guess()).format('DD-MM-yyyy HH:mm');
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.tableBlock.heads.userBlock'),
        accessor: 'userBlock',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.userBlock) {
            return `${row.original.userBlock.name} ${row.original.userBlock.surname}`;
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.user.tableBlock.heads.platform'),
        accessor: 'platform',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.tableBlock.heads.message'),
        accessor: 'message',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.user.tableBlock.heads.status'),
        accessor: 'status',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.status) {
            return `${t(row.original.status)}`;
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 120,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <Can do={USER_PERMISSIONS.USER_BLOCK_UPDATE}>
            <LinkActionStyled
              to={`/adm/administration/user/block/${obj.original.uuid}`}
              type="routerDom"
              variant="secondary"
            >
              <i className="fas fa-pencil-alt"></i>
            </LinkActionStyled>
          </Can>
        </div>
      </>
    );
  };

  handlerGoToClientView = async (uuid) => {
    const result = await UserService.getUserToken(uuid);
    if (result && result.data?.token) {
      window
        .open(`${process.env.REACT_APP_IMPERSONATE_LOGIN}/${result.data.token}`, '_blank')
        .focus();
    }
  };

  toList = async (filter) => {
    const { setFilters, filtersSaved, backFilter } = this.props;
    if (backFilter) {
      setFilters(filtersSaved.filters);
      filter = filtersSaved;
    }
    const result = await UserService.listFilterBlockedRequest(filter);
    GeneralServices.setFilterBack(false);
    return result;
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          // title={t('forms.user.titleList')}
          defaultPageSize={PAGE_SIZE_10}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd="fas fa-plus"
          variantTitle="secondary"
          classNameTable="payper-table"
        ></PaginateList>
      </div>
    );
  }
}

UserBlockReviewListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  setFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filtersSaved: getFilterSelector(state),
  backFilter: getBackFilterSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(UserBlockReviewListContainer));
