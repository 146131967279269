import React from 'react';
import { EmployerFilterList } from '@smartups-sl/react-component-library';
import Layout from '../../../../components/layouts/verticalLayout';
import PATHS from '../../../../constants/paths';
import { ROLES } from '../../../../constants/index';
import { actions } from '../../../../api/index';
import { urlBase } from '../../../../api/urls';

const employerPaths = {
  UPDATE_EMPLOYER: '/adm/administration/employer',
  CREATE_EMPLOYER: PATHS.CREATE_EMPLOYER,
};

const EmployerListPage = (props) => {
  return (
    <Layout
      body={
        <EmployerFilterList
          employerPaths={employerPaths}
          pageSize={10}
          roles={ROLES}
          urlBase={urlBase}
          actions={actions}
          titleVariant="secondary"
          iconAddVariant="terciary"
        />
      }
    />
  );
};

EmployerListPage.propTypes = {};

export default EmployerListPage;
