import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import UserBlockReviewForm from './userBlockReviewForm.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import { showDialog } from '@smartups-sl/react-utils-components';
import * as GeneralServices from '../../../../services/GeneralServices';

class UserContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    if (this.props.uuid) {
      AdminServices.getUserBlockRequest(this.props.uuid).then((result) => {
        console.log(result);
        if (result) {
          this.setState({ data: result });
        }
      });
    }
  }

  componentWillUnmount() {
    this.setState = (state, callback) => {
      return;
    };
  }

  handleSubmit = async (values) => {
    // try {
    //   if (values && this.props.uuid) {
    //     const result = await AdminServices.updateDataUser(values, this.props.uuid);
    //     let title = '';
    //     let text = '';
    //     let icon = '';
    //     if (result && result === 'OK') {
    //       title = this.props.t('forms.user.messages.update.title');
    //       text = this.props.t('forms.user.messages.update.success');
    //       icon = 'success';
    //     } else {
    //       title = 'Error';
    //       text = this.props.t('forms.user.messages.update.error');
    //       icon = 'error';
    //     }
    //     let okAction = undefined;
    //     okAction = () => {
    //       this.props.history.push(PATHS.USER_LIST);
    //     };
    //     showDialog(title, text, icon, false, 'OK', false, okAction);
    //   }
    // } catch (error) {
    //   //console.log('Error', error);
    // }
  };

  handleAction = async (action) => {
    const { data } = this.state;

    let ok = false;
    switch (action) {
      case 'cancel':
        this.props.history.push(PATHS.USER_LIST_REVIEW);
        break;
      case 'block':
        if (data.uuid && data.status === 'PENDING') {
          const result = await AdminServices.updateStatusBlock(data.uuid, 'BLOCKED');
          if (result) {
            ok = true;
          }
        }
        break;
      case 'notBlocked':
        if (data.uuid && data.status === 'PENDING') {
          const result = await AdminServices.updateStatusBlock(data.uuid, 'NOT_BLOCKED');
          if (result) {
            ok = true;
          }
        }
        break;
      default:
        this.props.history.push(PATHS.USER_LIST_REVIEW);
        break;
    }

    if (ok) {
      const okAction = () => {
        this.props.history.push(PATHS.USER_LIST_REVIEW);
      };

      showDialog(
        this.props.t('forms.user.messages.update.title'),
        this.props.t('forms.user.messages.update.success'),
        'success',
        false,
        'OK',
        false,
        okAction
      );
    }
  };

  render() {
    return (
      <>
        <UserBlockReviewForm data={this.state.data} action={this.handleAction} />
      </>
    );
  }
}

UserContainer.propTypes = {
  t: PropTypes.any,
};

export default withRouter(withTranslation()(UserContainer));
