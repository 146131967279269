import React, { useState } from 'react';
import Header from '../commons/header';
import { AdministrationContainer, ButtonCollapseSidebar } from './styled';
import { ProSidebar, SidebarHeader } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getAuthTokenSelector, getUserRoleSelector } from '../../../store/selectors/authSelector';

const AdministrationLayout = (props) => {
  const { role, token } = props;
  const [expanded, setExpanded] = useState(false);
  // const { t } = useTranslation();

  return (
    <div id="sb-site">
      <div id="page-wrapper" className="container-fluid">
        <Header
          showUserMenu={props.showUserMenu !== undefined ? props.showUserMenu : undefined}
          role={role}
          token={token}
        />

        <div className="administration-layout row">
          <ProSidebar collapsed={expanded}>
            <SidebarHeader>
              <ButtonCollapseSidebar
                onClick={() => {
                  setExpanded(!expanded);
                }}
              >
                {expanded ? (
                  <i className="fas fa-angle-double-right"></i>
                ) : (
                  <i className="fas fa-angle-double-left"></i>
                )}
              </ButtonCollapseSidebar>
            </SidebarHeader>
            {/* <Can do={ACTION.LIST} on={DOMAIN.USER}>
              <Menu iconShape="square">
                <MenuItem icon={<i className="fas fa-users"></i>}>
                  <Link to={PATHS.USER_LIST}>
                    {t('administration.options.users.menuTitle')}
                  </Link>
                </MenuItem>
              </Menu>
            </Can> */}
            {/* <Can do={ACTION.LIST} on={DOMAIN.EMPLOYEE}>
              <Menu iconShape="square">
                <MenuItem icon={<i className="fas fa-user-tie"></i>}>
                  <Link to={PATHS.EMPLOYER_LIST}>
                    {t('administration.options.employer.menuTitle')}
                  </Link>
                </MenuItem>
              </Menu>
            </Can> */}
          </ProSidebar>
          <AdministrationContainer expanded={expanded} id="page-content-wrapper-adm">
            <div id="page-content" className="container-fluid container-fixed-scrollable">
              <div className="row justify-content-center">
                <div className="col-12">{props.body}</div>
              </div>
            </div>
          </AdministrationContainer>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: getAuthTokenSelector(state),
  role: getUserRoleSelector(state),
  // timeZone: getTimeZoneSelector(state),
});

export default connect(mapStateToProps, {})(AdministrationLayout);
