import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import * as AdminServices from '../../../../services/AdminService';
import * as UserService from '../../../../services/UserService';
import UnilevelTree from './unilevelTree.view';
import { withTranslation } from 'react-i18next';
import PATHS from '../../../../constants/paths';
import * as GeneralServices from '../../../../services/GeneralServices';
import Filter from './userFilterUnilevel';
import { Title } from '@smartups-sl/react-component-library';

class UnilevelTreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      data: null,
      dataAll: null,
      nodeVisited: [],
      viewType: 'simple',
      filtered: false,
      onlyActive: false,
    };
  }

  componentDidMount() {
    if (this.props.uuid && this.props.uuid !== 'root') {
      AdminServices.getUser(this.props.uuid).then((resultUser) => {
        if (resultUser?.uuid) {
          AdminServices.getUnilevelTreeNodes(resultUser.uuid).then((result) => {
            if (result) {
              this.setState({ data: result, dataAll: result, user: resultUser });
            }
          });
        }
      });
    } else if (this.props.admin) {
      let uuid = 'admin';
      if (this.props.uuid && this.props.uuid !== 'root') {
        uuid = this.props.uuid;
      }
      AdminServices.getUser(uuid).then((resultUser) => {
        if (resultUser?.uuid) {
          AdminServices.getUnilevelAdminTreeNodes(resultUser.uuid).then((result) => {
            if (result) {
              this.setState({ data: result, dataAll: result, user: resultUser });
            }
          });
        }
      });
    }
  }

  getNodes = (uuid) => {
    if (this.state.viewType === 'simple') {
      if (uuid?.info?.userUuid) {
        const firstLevel = this.state.user.treeLevel;
        const levelArrows = firstLevel % 2;
        const visited = this.state.nodeVisited.includes(uuid.info.userUuid);
        if (uuid.info.level % 2 === levelArrows && visited === false) {
          AdminServices.getUnilevelTreeNodes(uuid.info.userUuid).then((result) => {
            const newData = [...this.state.data];
            let news = false;
            result.forEach((element) => {
              const item = newData.find((ele) => ele.userUuid === element.userUuid);
              if (item === undefined) {
                newData.push(element);
                news = true;
              }
            });
            const n = [...this.state.nodeVisited];
            if (!n.includes(uuid.info.userUuid)) {
              n.push(uuid.info.userUuid);
            }
            if (news) {
              this.setState({ data: newData, dataAll: newData, nodeVisited: n });
            } else {
              this.setState({ nodeVisited: n });
            }
          });
        }
      }
    } else {
      if (uuid) {
        AdminServices.getUnilevelTreeNodes(uuid).then((result) => {
          const newData = [...this.state.data];
          let news = false;
          result.forEach((element) => {
            const item = newData.find((ele) => ele.userUuid === element.userUuid);
            if (item === undefined) {
              newData.push(element);
              news = true;
            }
          });
          const n = [...this.state.nodeVisited];
          if (!n.includes(uuid)) {
            n.push(uuid);
          }
          if (news) {
            this.setState({ data: newData, dataAll: newData, nodeVisited: n });
          } else {
            this.setState({ nodeVisited: n });
          }
        });
      }
    }
  };

  getNodesAdmin = (uuid) => {
    if (this.state.viewType === 'simple') {
      if (uuid?.info?.userUuid) {
        const firstLevel = this.state.user.treeLevel;
        const levelArrows = firstLevel % 2;
        const visited = this.state.nodeVisited.includes(uuid.info.userUuid);
        if (uuid.info.level % 2 === levelArrows && visited === false) {
          AdminServices.getUnilevelAdminTreeNodes(uuid.info.userUuid).then((result) => {
            const newData = [...this.state.data];
            let news = false;
            result.forEach((element) => {
              const item = newData.find((ele) => ele.userUuid === element.userUuid);
              if (item === undefined) {
                newData.push(element);
                news = true;
              }
            });
            const n = [...this.state.nodeVisited];
            if (!n.includes(uuid.info.userUuid)) {
              n.push(uuid.info.userUuid);
            }
            if (news) {
              this.setState({ data: newData, dataAll: newData, nodeVisited: n });
            } else {
              this.setState({ nodeVisited: n });
            }
          });
        }
      }
    } else {
      if (uuid) {
        AdminServices.getUnilevelAdminTreeNodes(uuid).then((result) => {
          const newData = [...this.state.data];
          let news = false;
          result.forEach((element) => {
            const item = newData.find((ele) => ele.userUuid === element.userUuid);
            if (item === undefined) {
              newData.push(element);
              news = true;
            }
          });
          const n = [...this.state.nodeVisited];
          if (!n.includes(uuid)) {
            n.push(uuid);
          }
          if (news) {
            this.setState({ data: newData, dataAll: newData, nodeVisited: n });
          } else {
            this.setState({ nodeVisited: n });
          }
        });
      }
    }
  };

  handlerOnchangeViewType = (type) => {
    this.setState({ viewType: type });
  };

  handleOnback = () => {
    GeneralServices.setFilterBack(true);
    this.props.history.push(PATHS.USER_LIST);
  };

  handleSetFilter = async (filter) => {
    const filterData = {
      page: 1,
      pageSize: 1,
      filters: filter,
    };
    const result = await UserService.listFilterUser(filterData);
    if (result?.docs && result?.docs.length > 0) {
      AdminServices.getUser(result.docs[0].uuid).then((resultUser) => {
        if (resultUser?.uuid) {
          AdminServices.getUnilevelAdminTreeNodes(resultUser.uuid).then((resultNode) => {
            if (resultNode) {
              this.setState({
                data: resultNode,
                dataAll: resultNode,
                user: resultUser,
                filtered: true,
              });
            }
          });
        }
      });
    }
  };

  handlerCleanFilter = async (resetForm) => {
    resetForm();
    AdminServices.getUser('admin').then((resultUser) => {
      if (resultUser?.uuid) {
        AdminServices.getUnilevelAdminTreeNodes(resultUser.uuid).then((result) => {
          if (result) {
            this.setState({ data: result, dataAll: result, user: resultUser, filtered: false });
          }
        });
      }
    });
  };

  handlerOnChangeOnlyActive = async (value) => {
    if (value) {
      const onlyActive = [];
      for (const dat of this.state.dataAll) {
        if (dat.status === 'ACTIVE') {
          onlyActive.push(dat);
        }
      }
      this.setState({ onlyActive: value, data: onlyActive });
    } else {
      this.setState({ onlyActive: value, data: [...this.state.dataAll] });
    }
  };

  render() {
    const { t, admin } = this.props;
    return (
      <>
        <div className="row">
          <div className="col-12 marg-top-40px mb-4">
            <Title type="h3" variant="black">
              {t('unilevel')}
            </Title>
          </div>
        </div>
        {admin && (
          <div className="row">
            <div className="col-12">
              <Filter
                onSubmit={this.handleSetFilter}
                filtered={this.state.filtered}
                onClean={this.handlerCleanFilter}
              />
            </div>
          </div>
        )}
        <UnilevelTree
          data={this.state.data || []}
          getNodes={admin ? this.getNodesAdmin : this.getNodes}
          parentNode={this.state.user || undefined}
          nodeVisited={this.state.nodeVisited}
          viewType={this.state.viewType}
          onChangeType={this.handlerOnchangeViewType}
          t={t}
          onback={this.handleOnback}
          admin={admin}
          onlyActive={this.state.onlyActive}
          onChangeOnlyActive={this.handlerOnChangeOnlyActive}
        />
      </>
    );
  }
}

UnilevelTreeContainer.propTypes = {
  t: PropTypes.any,
  admin: PropTypes.bool,
};

export default withRouter(withTranslation()(UnilevelTreeContainer));
