import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import * as AdminServices from '../../../../services/AdminService';
import * as NewsService from '../../../../services/NewsService';
import * as GeneralServices from '../../../../services/GeneralServices';
import { withTranslation } from 'react-i18next';
import {
  PAGE_SIZE_10,
  NEWS_STATUS,
  NEWS_STATUS_DRAFT,
  NEWS_STATUS_TO_PUBLISH,
  NEWS_STATUS_DELETED,
} from '../../../../constants/index';
import { showDialog } from '@smartups-sl/react-utils-components';
import { LinkActionStyled } from './newsList.styled';
import { PaginateList } from '@smartups-sl/react-complex-components';
import { USER_PERMISSIONS } from '../../../permissions/rolesPermission';
import { Can, AbilityContext } from '../../../permissions/Can';
import { connect } from 'react-redux';
import {
  getFilterSelector,
  getBackFilterSelector,
} from '../../../../store/selectors/filterSelector';
import PATHS from '../../../../constants/paths';
import moment from 'moment-timezone';

class NewsListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refreshData: false,
      countries: null,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevState) {
    if (prevState.refreshData !== this.state.refreshData && this.state.refreshData === true) {
      this.resetRefreshData();
    }
  }

  resetRefreshData = () => {
    this.setState({ refreshData: false });
  };

  getHeaderTable = (text) => {
    const { t } = this.props;
    return <span title={t(text)}>{t(text)}</span>;
  };

  getColumns = () => {
    const { t } = this.props;
    return [
      {
        Header: this.getHeaderTable('forms.news.table.heads.date'),
        accessor: 'createdAt',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.createdAt) {
            const dateForm = moment(row.original.createdAt);
            return dateForm.tz(moment.tz.guess()).format('DD-MM-yyyy HH:mm');
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.news.table.heads.title'),
        accessor: 'title',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.news.table.heads.platform'),
        accessor: 'platform',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.news.table.heads.resume'),
        accessor: 'resume',
        className: 'text-center',
      },
      {
        Header: this.getHeaderTable('forms.news.table.heads.status'),
        accessor: 'status',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.status) {
            const status = NEWS_STATUS.find((st) => st.code === row.original.status);
            return status ? t(status.label) : row.original.status;
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('forms.news.table.heads.lang'),
        accessor: 'lang',
        className: 'text-center',
        Cell: (row) => {
          if (row.original.lang && row.original.lang.length > 0) {
            return row.original.lang.join(', ');
          }
          return '';
        },
      },
      {
        Header: this.getHeaderTable('generic.tables.actions'),
        className: 'align-self-center',
        accessor: 'uuid',
        width: 120,
        Cell: (row) => this.renderActions(row),
      },
    ];
  };

  renderActions = (obj) => {
    return (
      <>
        <div style={{ textAlign: 'center' }}>
          <LinkActionStyled
            to={`/adm/administration/news/form/view/${obj.original.uuid}`}
            type="routerDom"
            variant="secondary"
          >
            <i className="fas fa-eye"></i>
          </LinkActionStyled>
          {obj.original.status === NEWS_STATUS_DRAFT.code && (
            <>
              <Can do={USER_PERMISSIONS.NEWS_CREATE}>
                <LinkActionStyled
                  to={`/adm/administration/news/form/update/${obj.original.uuid}`}
                  type="routerDom"
                  variant="secondary"
                >
                  <i className="fas fa-edit" title="Editar" />
                </LinkActionStyled>
              </Can>
              <Can do={USER_PERMISSIONS.NEWS_PUBLISH}>
                <LinkActionStyled
                  variant="secondary"
                  onClick={() => this.handlerToPublish(obj.original.uuid)}
                >
                  <i className="far fa-paper-plane"></i>
                </LinkActionStyled>
              </Can>
            </>
          )}

          {obj.original.status === NEWS_STATUS_TO_PUBLISH.code && (
            <>
              <Can do={USER_PERMISSIONS.NEWS_PUBLISH}>
                <LinkActionStyled
                  variant="secondary"
                  onClick={() => this.handlerPublish(obj.original.uuid)}
                >
                  <i className="fas fa-check"></i>
                </LinkActionStyled>
              </Can>
            </>
          )}

          {obj.original.status !== NEWS_STATUS_DELETED.code && (
            <>
              <Can do={USER_PERMISSIONS.NEWS_DELETE}>
                <LinkActionStyled
                  variant="secondary"
                  onClick={() => this.handlerDelete(obj.original.uuid)}
                >
                  <i className="fas fa-trash-alt"></i>
                </LinkActionStyled>
              </Can>
            </>
          )}
        </div>
      </>
    );
  };

  handlerDelete = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await NewsService.sendDeleteNews(value);
      if (result) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.news.dialog.delete.title'),
      t('forms.news.dialog.delete.text'),
      'info',
      true,
      t('forms.news.dialog.delete.buttonOk'),
      false,
      okAction,
      t('forms.news.dialog.delete.buttonNo')
    );
  };

  handlerToPublish = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await NewsService.sendToPublish(value);
      if (result) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.news.dialog.toPublish.title'),
      t('forms.news.dialog.toPublish.text'),
      'info',
      true,
      t('forms.news.dialog.toPublish.buttonOk'),
      false,
      okAction,
      t('forms.news.dialog.toPublish.buttonNo')
    );
  };

  handlerPublish = (value) => {
    const { t } = this.props;

    const okAction = async () => {
      const result = await NewsService.publish(value);
      if (result) {
        this.setState({ refreshData: true });
      }
    };

    showDialog(
      t('forms.news.dialog.publish.title'),
      t('forms.news.dialog.publish.text'),
      'info',
      true,
      t('forms.news.dialog.publish.buttonOk'),
      false,
      okAction,
      t('forms.news.dialog.publish.buttonNo')
    );
  };

  toList = async (filter) => {
    const { setFilters, filtersSaved, backFilter } = this.props;
    if (backFilter) {
      setFilters(filtersSaved.filters);
      filter = filtersSaved;
    }
    filter.sort = '-createdAt';
    const result = await NewsService.listFilterNews(filter);
    GeneralServices.setFilterBack(false);
    return result;
  };

  canAccessToCreate = () => {
    return (
      this.context.can(USER_PERMISSIONS.NEWS_CREATE) ||
      this.context.can(USER_PERMISSIONS.NEWS_CREATE_FIDELITY) ||
      this.context.can(USER_PERMISSIONS.NEWS_CREATE_SMARTNET)
    );
  };

  render() {
    const { t, filters, className } = this.props;
    return (
      <div className={className || ''}>
        <PaginateList
          title={t('forms.news.titleList')}
          defaultPageSize={PAGE_SIZE_10}
          formlinkToAdd={this.canAccessToCreate() ? PATHS.CREATE_NEWS : undefined}
          columns={this.getColumns()}
          toList={this.toList}
          filters={filters}
          forceRefresh={this.state.refreshData}
          iconAdd="fas fa-plus"
          variantTitle="secondary"
          classNameTable="payper-table"
          classNameLinkAdd="actionAdd"
          floatButton
        ></PaginateList>
      </div>
    );
  }
}

NewsListContainer.contextType = AbilityContext;

NewsListContainer.propTypes = {
  t: PropTypes.any,
  filters: PropTypes.object,
  className: PropTypes.string,
  setFilters: PropTypes.func,
};

const mapStateToProps = (state) => ({
  filtersSaved: getFilterSelector(state),
  backFilter: getBackFilterSelector(state),
});

export default connect(mapStateToProps, {})(withTranslation()(NewsListContainer));
