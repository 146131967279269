import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import { RowPrincipal, ColNopadding, ImageAvatar, Row, ColNopaddingIMG, ColCenter } from './styled';
// import { USER_LEVELS, BULRRUNCOIN } from '../../../../../constants';

const SimpleTreeNode = (props) => {
  const { data } = props;
  // const { t } = useTranslation();
  const noImage = `/assets/themes/${process.env.REACT_APP_THEME}/images/no-image.png`;
  // const currentLevel = USER_LEVELS.find(
  //   (element) => element.value === data.info.rank
  // );
  // const level1 = firstLevel + 1;
  return (
    <RowPrincipal
      key={`${data.id}_node`}
      status={data.info.status}
      id={data.id}
      className="row nodeSimple"
    >
      {(data.info.image && data.info.image.length > 0) ||
      (data.info.avatarUrl && data.info.avatarUrl.length > 0) ? (
        <ColNopaddingIMG className="col-1 col-md-1 col-lg-1 d-none d-md-block">
          <ImageAvatar
            alt="Avatar"
            src={data.info.avatarUrl ? data.info.avatarUrl : data.info.image}
          ></ImageAvatar>
        </ColNopaddingIMG>
      ) : (
        <ColNopaddingIMG className="col-1 col-md-1 col-lg-1 d-none d-md-block">
          <ImageAvatar alt="Avatar" src={noImage}></ImageAvatar>
        </ColNopaddingIMG>
      )}
      {/* {currentLevel && (
        <>
          <ColNopaddingRANK className="col-2 col-md-2 col-lg-2 no-padding">
            <ImageRank
              alt={t(currentLevel.label)}
              title={t(currentLevel.label)}
              src={`/assets/images/images-resources/${currentLevel?.image}`}
            ></ImageRank>
            <RankText
              title={t(currentLevel.label)}
              className="d-none d-md-block"
            >
              {t(currentLevel.label)}
            </RankText>
          </ColNopaddingRANK>
        </>
      )} */}
      <ColCenter className="col-9 col-md-9 col-lg-9">
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.email}</ColNopadding>
        </Row>
        <Row>
          <ColNopadding className="col-12 col-md-12 col-lg-12">{data.info.name}</ColNopadding>
        </Row>
      </ColCenter>
    </RowPrincipal>
  );
};

SimpleTreeNode.propTypes = {
  node: PropTypes.object,
  onClickNode: PropTypes.func,
  firstLevel: PropTypes.number,
  nodeVisited: PropTypes.array,
};

export default SimpleTreeNode;
