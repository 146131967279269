import { createAction } from 'redux-actions';
import { apiPut, apiGetFile, apiGet } from '../../api/index';
import {
  urlauthProfile,
  urlAvatar,
  urlGetAvatar,
  urlFilterUser,
  urlGetClientToken,
  urlauthChangePass,
  urlFilterUserReview,
  urlFilterUserBlockRequest,
  urlKycResetUser,
} from '../../api/urls';
import {
  UPDATE_PROFILE,
  UPDATE_AVATAR,
  GET_AVATAR,
  FILTER_USERS,
  PAGE_SIZE_10,
  GET_CLIENT_TOKEN,
} from '../../constants/index';

const generateFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.name) filter += '&name=' + filters.name;
    if (filters.email) filter += '&email=' + filters.email;
    if (filters.uuid) filter += '&uuid=' + filters.uuid;
    if (filters.nickname) filter += '&nickname=' + filters.nickname;
    if (filters.active) filter += '&active=' + filters.active;
    if (filters.passedKYC) filter += '&passedKYC=' + filters.passedKYC;
  }

  return filter;
};

const generateBlockFilterQuery = ({ filters, page, pageSize }) => {
  let filter = '?';

  page ? (filter += 'page=' + page) : (filter += 'page=1');
  pageSize ? (filter += '&pageSize=' + pageSize) : (filter += '&pageSize=' + PAGE_SIZE_10);

  if (filters) {
    if (filters.status) filter += '&status=' + filters.status;
  }

  return filter;
};

/**
 * Get auth info profile (user loged).
 */
export const updateProfile = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthProfile, obj, undefined, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const updatePassword = createAction(UPDATE_PROFILE, (obj) =>
  apiPut(urlauthChangePass, obj, undefined, true)()
);

/**
 * Update User Avatar.
 */
export const updateUserAvatar = createAction(UPDATE_AVATAR, (obj) =>
  apiPut(urlAvatar, obj, undefined, true)()
);

/**
 * Get User Avatar.
 */
export const getAvatar = createAction(GET_AVATAR, (param) =>
  apiGetFile(urlGetAvatar, param, true)()
);

/**
 * Filter list users.
 */
export const filterUsers = createAction(FILTER_USERS, (filter, param) =>
  apiGet(urlFilterUser + generateFilterQuery(filter), param, true)()
);

/**
 * Get Client token.
 */
export const getClientToken = createAction(GET_CLIENT_TOKEN, (param) =>
  apiGet(urlGetClientToken, param, true)()
);

/**
 * Filter list users reviews.
 */
export const filterUsersReview = createAction(FILTER_USERS, (filter, param) =>
  apiGet(urlFilterUserReview + generateFilterQuery(filter), param, true)()
);

/**
 * Filter list users reviews.
 */
export const filterRequestBlocked = createAction(FILTER_USERS, (filter, param) =>
  apiGet(urlFilterUserBlockRequest + generateBlockFilterQuery(filter), param, true)()
);

/**
 * Get auth info profile (user loged).
 */
export const resetKycUser = createAction('RESET_KYC_USER', (param) =>
  apiPut(urlKycResetUser, {}, param, true)()
);
