import styled from 'styled-components';

export const Medal = styled.img`
  width: 20px !important;
  height: 15px !important;
  float: inherit !important;
  margin-top: -5px;
`;

export const Row = styled.div`
  margin-right: 0px;
  margin-left: 0px;
`;

export const ColNopadding = styled.div`
  padding-right: 0px !important;
  padding-left: 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NodeContainer = styled.div`
  background: ${({ status }) => (status && status === 'ANULATED' ? '#ffa6a6' : '#fff')};
  padding: 7px 7px;
  border: 2px solid #26abe1;
  border-radius: 15px;
  opacity: ${({ status }) =>
    status && status === 'IMPORTED' ? 0.2 : status && status === 'ANULATED' ? 0.5 : 1};
`;

export const Arrow = styled.img`
  width: 30% !important;
  height: 5px !important;
  float: inherit !important;
  margin-top: -10px;
`;

export const ColArrow = styled.div`
  height: 8px !important;
  cursor: pointer;
`;
