import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';
import { withTranslation } from 'react-i18next';
import { InputTextFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './userFilterUnilevel.data.form';
import { Button } from '@smartups-sl/react-component-library';

const UserFilterUnilevelForm = (props) => {
  const { t, handleSubmit, filtered, onClean } = props;
  return (
    <>
      <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-4">
            <Field
              id="email"
              className="form-control"
              name="email"
              component={InputTextFormik}
              fieldType="text"
              placeholder={t('forms.user.labels.email')}
            />
          </div>
          <div className="col-2">
            <Button type="submit" className="btn btn-md me-2" variant="primary">
              <i className="fas fa-search"></i>
              {`  ${t('forms.user.buttons.search')}  `}
            </Button>
            {filtered && (
              <Button
                type="button"
                className="btn btn-md"
                variant="secondary"
                onClick={() => onClean(props.resetForm)}
                title={t('cleanFilter')}
              >
                <i className="fas fa-times"></i>
              </Button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

UserFilterUnilevelForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onClean: PropTypes.func,
  filtered: PropTypes.bool,
};

const UserFilterUnilevelFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props, resetForm }) => {
    props.onSubmit(values, resetForm);
  },
  enableReinitialize: true,
  displayName: 'UserFilterUnilevelForm',
})(withTranslation()(UserFilterUnilevelForm));

export default UserFilterUnilevelFormF;
