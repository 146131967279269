const PATHS = {};

PATHS.INIT = '/';
PATHS.ERROR = '/error';
PATHS.LOGOUT = '/logout';
PATHS.LOGIN = '/login';
PATHS.RECOVERY_PASSWORD = '/recovery/password/:token';
PATHS.ADMIN = '/adm';
PATHS.FORGOT_PASSWORD = '/forgotPassword';
PATHS.MY_PROFILE = '/myProfile';
PATHS.ADMIN_HOME = '/home';
PATHS.SECURITY = '/security';

/** ADMIN PATH */
PATHS.ADMINISTRATIONS = '/adm/administration';

PATHS.EMPLOYER = '/adm/administration/employer';
PATHS.EMPLOYER_LIST = '/adm/administration/employer/list';
PATHS.CREATE_EMPLOYER = '/adm/administration/employer/new';
PATHS.UPDATE_EMPLOYER = '/adm/administration/employer/:uuid';
PATHS.PERMISSIONS = '/adm/administration/permissions/list';
PATHS.UPDATE_PERMISSIONS = '/adm/administration/permissions/form/update/:uuid';

PATHS.USER_LIST = '/adm/administration/user';
PATHS.USER_LIST_REVIEW = '/adm/administration/user/review';
PATHS.USER_REVIEW_DETAIL = '/adm/administration/user/review/detail/:uuid';
PATHS.UPDATE_USER = '/adm/administration/user/:uuid';
PATHS.RECOVERY_TFA = '/recovery/TFA/:token';
PATHS.USER_TREE = '/adm/administration/user/tree/:uuid';
PATHS.USER_BLOCK_DETAIL = '/adm/administration/user/block/:uuid';

PATHS.ADMIN_TREE = '/adm/administration/admin/tree/:uuid';
PATHS.ADMIN_TREE_SIMPLE = '/adm/administration/admin/tree';

PATHS.NEWS = '/adm/administration/news';
PATHS.NEWS_LIST = '/adm/administration/news/list';
PATHS.CREATE_NEWS = '/adm/administration/news/new';
PATHS.UPDATE_NEWS = '/adm/administration/news/form/update/:uuid';
PATHS.VIEW_NEWS = '/adm/administration/news/form/view/:uuid';

PATHS.MARKETING = '/adm/administration/resources';
PATHS.MARKETING_LIST = '/adm/administration/resources/list';
PATHS.CREATE_MARKETING = '/adm/administration/resources/new';
PATHS.UPDATE_MARKETING = '/adm/administration/resources/form/update/:uuid';

/* GENERATOR_INJECT_PATHS */

export default PATHS;
