import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import AuthAdminRoute from '../Security/AuthAdminRoute';
import PATHS from '../../constants/paths';
import NewsFormPage from '../../pages/administration/news/form';
import ListNewsPage from '../../pages/administration/news/list';
import NewsViewPage from '../../pages/administration/news/view';

class NewsRoutesContainer extends Component {
  componentDidMount() {}

  render() {
    return (
      <Switch>
        <AuthAdminRoute exact path={PATHS.NEWS_LIST} component={ListNewsPage} />
        <AuthAdminRoute exact path={PATHS.CREATE_NEWS} component={NewsFormPage} />
        <AuthAdminRoute
          exact
          path={PATHS.VIEW_NEWS}
          component={(props) => <NewsViewPage uuid={props.match.params.uuid} />}
        />
        <AuthAdminRoute
          exact
          path={PATHS.UPDATE_NEWS}
          component={(props) => <NewsFormPage uuid={props.match.params.uuid} />}
        />
      </Switch>
    );
  }
}

NewsRoutesContainer.propTypes = {};

export default NewsRoutesContainer;
