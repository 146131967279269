import React from 'react';
import PropTypes from 'prop-types';
import { EmployerForm } from '@smartups-sl/react-component-library';
import Layout from '../../../../components/layouts/verticalLayout';

import PATHS from '../../../../constants/paths';
import { ROLES } from '../../../../constants/index';
import { actions } from '../../../../api/index';
import { urlBase } from '../../../../api/urls';

const employerPaths = {
  EMPLOYER_LIST: PATHS.EMPLOYER_LIST,
};

const EmployerFormPage = (props) => {
  return (
    <Layout
      body={
        <>
          <EmployerForm
            uuid={props.uuid}
            roles={ROLES}
            employerPaths={employerPaths}
            urlBase={urlBase}
            actions={actions}
            titleVariant="secondary"
          />
        </>
      }
    />
  );
};

EmployerFormPage.propTypes = {
  uuid: PropTypes.string,
};

export default EmployerFormPage;
