import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer } from '../../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';
import moment from 'moment-timezone';
import { ContainerLabel, ContainerTextValue } from './userReviewForm.style';

const UserReviewForm = (props) => {
  const { data, actionDuplicated } = props;
  const { t } = useTranslation();

  const renderDataUser = (user) => {
    if (user) {
      const birdDate = moment(user.birdDate).format('DD-MM-YYYY');
      return (
        <>
          <div className="row">
            <ContainerLabel className="col-12">{t('forms.user.labels.email')}</ContainerLabel>
          </div>
          <div className="row">
            <ContainerTextValue className="col-12">{user.email}</ContainerTextValue>
          </div>
          <div className="row">
            <ContainerLabel className="col-12">{t('forms.user.labels.birthDate')}</ContainerLabel>
          </div>
          <div className="row">
            <ContainerTextValue className="col-12">{birdDate}</ContainerTextValue>
          </div>
          <div className="row">
            <ContainerLabel className="col-12">{t('forms.user.labels.name')}</ContainerLabel>
          </div>
          <div className="row">
            <ContainerTextValue className="col-12">{user.name}</ContainerTextValue>
          </div>
          <div className="row">
            <ContainerLabel className="col-12">{t('forms.user.labels.surname')}</ContainerLabel>
          </div>
          <div className="row">
            <ContainerTextValue className="col-12">{user.surname}</ContainerTextValue>
          </div>
          <div className="row">
            <ContainerLabel className="col-12">{t('forms.user.labels.kycLink')}</ContainerLabel>
          </div>
          <div className="row">
            <ContainerTextValue className="col-12">
              {user.kycUserUuid ? (
                <a
                  href={`${process.env.REACT_APP_GETID_DETAILS}/${user.kycUserUuid}`}
                  target="_blank"
                >
                  {`${process.env.REACT_APP_GETID_DETAILS}/${user.kycUserUuid}`}
                </a>
              ) : (
                ''
              )}
            </ContainerTextValue>
          </div>
        </>
      );
    }

    return '';
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12 col-md-10 marg-btm-1rem marg-top-40px">
          <Title type="h3" variant="secondary">
            {t('forms.user.titleReviewDetails')}
          </Title>
        </div>
      </div>
      {data && (
        <>
          <div className="row justify-content-center">
            <div className="col-12 col-md-10">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <Title type="h5" variant="secondary">
                        {t('forms.user.activeUser')}
                      </Title>
                    </div>
                  </div>
                  {renderDataUser(data.activeUser || null)}
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-12">
                      <Title type="h5" variant="secondary">
                        {t('forms.user.duplicatedUser')}
                      </Title>
                    </div>
                  </div>
                  {renderDataUser(data.duplicateUser || null)}
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons mt-5">
              <Button
                type="button"
                className="btn btn-lg"
                variant="secondary"
                onClick={() => actionDuplicated('cancel')}
              >
                {t('forms.user.buttons.backDuplicated')}
              </Button>
              <Button
                type="button"
                className="btn btn-lg"
                variant="primary"
                onClick={() => actionDuplicated('block')}
              >
                {t('forms.user.buttons.blockDuplicated')}
              </Button>
              <Button
                type="button"
                className="btn btn-lg"
                variant="primary"
                onClick={() => actionDuplicated('notBlocked')}
              >
                {t('forms.user.buttons.notDuplicated')}
              </Button>
            </ButtonsContainer>
          </div>
        </>
      )}
    </>
  );
};

UserReviewForm.propTypes = {
  data: PropTypes.object,
  actionDuplicated: PropTypes.func,
};

export default UserReviewForm;
