import React from 'react';
import PropTypes from 'prop-types';
import { withFormik, Field } from 'formik';

import { withTranslation } from 'react-i18next';

import { InputTextFormik, InputImageFormik } from '@smartups-sl/react-forms-components';
import { dataModel, validationDataModel, setInitialValues } from './myProfile.data.form';

import { ButtonsContainer } from '../../../design/styleComponents/globalComponents';
import { Button, Title } from '@smartups-sl/react-component-library';

const MyProfileForm = (props) => {
  const { t, handleSubmit, onback, getAvatar } = props;
  return (
    <>
      <div className="row">
        <div className="col-12 col-sm-10 col-lg-10 mb-5">
          <div className="row">
            <div className="col-12">
              <Title type="h3" variant="secondary">
                {t('forms.myProfile.title')}
              </Title>
            </div>
          </div>
          <form className="form-horizontal bordered-row" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 col-sm-7 col-lg-8">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="name"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.name')}
                      name="name"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="surname"
                      className="form-control"
                      labelField={t('forms.myProfile.labels.surname')}
                      name="surname"
                      component={InputTextFormik}
                      fieldType="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 order-first order-sm-last col-sm-5 col-lg-4">
                <div className="row">
                  <div className="col-12">
                    <Field
                      id="avatar"
                      className="avatar"
                      labelField={t('forms.myProfile.labels.avatar')}
                      name="avatar"
                      component={InputImageFormik}
                      showPreview
                      singleImage
                      i18n={t}
                      withIcon={false}
                      withLabel={false}
                      functionGetImage={getAvatar}
                      buttonText={t('generic.image.choose')}
                      maxFileSize={1000000}
                      allowExtensions={['.jpg', '.png']}
                      textFileSizeError={t('generic.image.tooBig')}
                      textFileTypeError={t('generic.image.noext')}
                      resizeImage
                      resizeCompressFormat="JPEG"
                      resizeWidth={230}
                      resizeHeight={230}
                      resizeOutType="base64"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <ButtonsContainer className="col-10 col-sm-10 text-center containerButtons">
                <Button
                  type="button"
                  className="btn btn-lg"
                  variant="secondary"
                  onClick={() => onback()}
                >
                  {t('forms.myProfile.buttons.back')}
                </Button>

                <Button type="submit" className="btn btn-lg" variant="primary">
                  {t('forms.myProfile.buttons.save')}
                </Button>
              </ButtonsContainer>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

MyProfileForm.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  onback: PropTypes.func,
  getAvatar: PropTypes.func,
};

const MyProfileFormF = withFormik({
  mapPropsToValues: (props) => {
    if (props.initialValues) {
      return setInitialValues(props.initialValues);
    }
    return dataModel;
  },
  validationSchema: validationDataModel,
  handleSubmit: (values, { props }) => {
    props.onSubmit(values);
  },

  displayName: 'MyProfileForm',
})(withTranslation()(MyProfileForm));

export default MyProfileFormF;
